import React, { useEffect, useState } from "react";
import { Card, Flex, Button, Text, Image, Alert } from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  checkCameraPermission,
  requestCameraAccess,
} from "../services/cameraUtils";

const CameraComponent = ({ handleFileChange, picturePreview }) => {
  // const [cameraState, setCameraState] = useState(<></>);
  const [preview, setPreview] = useState(picturePreview);
  const [file, setFile] = useState(null);
  const [cameraAccess, setCameraAccess] = useState(false);

  const handleAccess = () => {
    const access = requestCameraAccess();
    if (access) {
      return true;
    } else {
      return false;
    }
  };

  const CameraAccessAlert = () => {
    const permission = checkCameraPermission();
    setCameraAccess(permission);
    if (permission === "granted") {
      setCameraAccess(true);
      console.log("Permission granted");
    } else {
      setCameraAccess(false);
      // if (access) {
      //   console.log("Access granted");
      //   setCameraAccess(true);
      // } else {
      //   console.log("Access denied");
      //   setCameraAccess(false);
      // }
    }
    return (
      <Card>
        <Alert variation="info">
          <Text>Grant access to your camera to take pictures.</Text>
          <Button
            onClick={() => {
              const access = requestCameraAccess();
              if (access) {
                setCameraAccess(true);
              } else {
                setCameraAccess(false);
              }
            }}
          >
            Grant Access
          </Button>
        </Alert>
      </Card>
    );
  };

  useEffect(() => {
    // Define constraints for the video stream
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia is not supported");
      return;
    }
  }, []);

  //
  const handleFileUpload = (event) => {
    if (!event)
      return;
    try {
      const file = event.target.files[0];

      handleFileChange(file);
      setFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        //   const fileContents = e.target.result;

        // setPreview(e.target.result);

        //   const displayDiv = document.getElementById("fileContents");
        //   displayDiv.innerText = fileContents;
      };
      let dataUrl = reader.readAsDataURL(file);
      setFile(dataUrl);
      // reader.readAsText(file);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };
  return (
    <Flex direction={"column"}>
      {cameraAccess ?? <CameraAccessAlert />}
      {/* <Card> */}
      {/* {cameraState}
       */}
      {/* <StorageManager
          acceptedFileTypes={["image/*"]}
          path={({ identityId }) => `protected/${identityId}/`}
          maxFileCount={1}
          isResumable
        /> */}
      {file ? (
        <StorageManager
          acceptedFileTypes={["image/*"]}
          path={({ identityId }) => `protected/${identityId}/`}
          maxFileCount={1}
          isResumable 
        />
        // <input
        //   type="file"
        //   accept="image/*"
        //   onChange={handleFileUpload}
        //   value={file}
        // />
      ) : (
        <input type="file" accept="image/*" onChange={handleFileUpload} />
      )}
      {picturePreview && (
        <Card style={{ textAlign: "center" }}>
          {/* <Text>Preview:</Text> */}
          <Image
            id="previewImage"
            maxWidth={"50%"}
            src={picturePreview}
            alt="pic"
          />
        </Card>
      )}
      {/* <div id="fileContents"></div> */}
      {/* <video ref={videoRef} style={{ width: '100%' }} autoPlay playsInline muted></video> */}
      {/* Optionally, include a message or an alternative input method here based on state */}
      {/* </Card> */}
    </Flex>
  );
};

export default CameraComponent;
