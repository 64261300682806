import { Alert, Card, Input, Label, Text } from "@aws-amplify/ui-react";
import { Geo } from "aws-amplify";
import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listListings } from "../graphql/queries";

const searchOptionsWithSearchAreaConstraints = {
  countries: ["USA"], // Alpha-3 country codes
  maxResults: 5, // 50 is the max and the default
  // searchIndexName: string, // the string name of the search index
};
const listings: any = async function() {
  return API.graphql(graphqlOperation(listListings));
};
type AddressSearchProps = {
  // other props...
  address: string;
  onChange: (address: string) => void;
  onAddressChange: (address: string) => void;
  setAddress: (address: string) => void;
  state: {
    // address: string | undefined,
    results: [];
    suggestions: [];
    searchOptionsWithSearchAreaConstraints: [];
  };
};
// class AddressSearch extends React.Component {
const AddressSearch = ({
  onChange,
  setAddress,
  address,
  onAddressChange,
}: AddressSearchProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<(string | undefined)[]>(
    []
  );
  const debounceTimer = React.useRef<NodeJS.Timeout | null>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [listingsData, setListingsData] = React.useState<any[]>([]);
  const data = async () => {
    return await listings().then((response: { data: any }) => {
      setListingsData(response.data.listListings.items);
      return response.data.listListings.items;
    });
  };
  React.useEffect(() => {
    data();
  }, []);
  const isAddressAvailable = (address: string) => {
    const takenAddresses = listingsData.map((l: any) => l.address);
    if (takenAddresses.includes(address)) {
      setErrorMessage(`Property already under agreement.`);
      setShowAlert(true);
      return false;
    } else {
      setShowAlert(false);
      setErrorMessage("");
      return true;
    }
  };
  const handleSuggestionClick = (address: string | undefined) => {
    if (address) {
      setInputValue(address);
      // setAddress(address);
    }

    if (onAddressChange) {
      isAddressAvailable(address!); // check availability
      onAddressChange(address || ""); // Add null check and provide a default value
    }
  };
  const showSuggestions = async (value: string) => {
    try {
      if (value) {
        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(async () => {
          const response = await Geo.searchByText(
            value,
            searchOptionsWithSearchAreaConstraints
          );
          const addresses = response.map((result) => {
            // console.log(result);
            return result.label;
          });
          setSuggestions(addresses);
        }, 300);
      } else {
        setSuggestions([]);
        setAddress("");
      }
    } catch (error) {
      // console.log("Error:", error);
    }
  };

  return (
    <>
      <Label>Address</Label>
      {showAlert && <Alert variation="error">{errorMessage}</Alert>}
      <Input
        type="text"
        value={inputValue}
        onChange={(e) => {
          showSuggestions(e.target.value);
          setInputValue(e.target.value);
          setSuggestions([]);
        }}
        onBlur={() => {
          isAddressAvailable(inputValue);
        }}
      />
      {suggestions.length > 0 && (
        <Card variation="outlined">
          {suggestions.map((result) => (
            <Card
              variation="outlined"
              key={Math.random()}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                handleSuggestionClick(result);
                setSuggestions([]);
              }}
            >
              <Text>{result}</Text>
            </Card>
          ))}
        </Card>
      )}
    </>
  );
};

export default AddressSearch;
