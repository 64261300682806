import React from "react";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import UserContext from "./UserContext";
import Header from "./Header";
import {
  ThemeProvider,
  createTheme,
  Card,
  Heading,
  View,
  Button,
} from "@aws-amplify/ui-react";
import Home from "./Home";
import Profile from "./Profile";
import Footer from "./components/Footer";
import SetCommission from "./SetCommission";
import AuthModal from "./AuthModal";
import SearchListings from "./SearchListings";
import MyListings from "./MyListings";
import MainNavBar from "./MainNavBar";
import ContactUs from "./ContactUsPage";
import AboutUs from "./AboutUs";
import ProductInfo from "./ProductInfo";
import AlertModule from "./components/AlertModule";
import PrivacyPolicy from "./components/PrivacyPolicy";

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  };
  static contextType = UserContext;
  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.context.updateCurrentUser();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    const { component: Component, ...rest } = this.props;
    const isAuthenticated =
      this.context.user && this.context.user.username ? true : false;
    const isLoaded = this.context.isLoaded;
    if (!isLoaded) return null;

    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <Component {...props} user={this.context.user} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
              }}
            />
          );
        }}
      />
    );
  }
}

const customTheme = createTheme({
  name: "custom-theme",
  tokens: {
    components: {
      // _nested: {
      //   backgroundColor: "#3d5f81",
      // },
      card: {
        // backgroundColor: { value: '#cdd4db' }, // Light grey background for Card
        color: { value: "#333" },
        padding: { value: "1rem" },
        borderRadius: { value: "8px" },
        boxShadow: { value: "0 5px 5px rgba(9, 10, .5, 0.5)" },
        margin: { value: "large" },
        _nested: {
          card: {
            margin: { value: "2em" },
            // backgroundColor: { value: "green" },
            // backgroundColor: { value: "hsl(210deg 29.03% 24.31%)" },
            // color: { value: "white" },
          },
          label: {
            fontSize: { value: "9.5rem" },
          },
        },
      },
      view: {
        backgroundColor: { value: "rgb(44, 62, 80) !important" }, // White background for View
        color: { value: "#000" },
        padding: { value: "1rem" },
      },
      text: {
        color: { value: "#333" },
        fontSize: { value: "1rem" },
        fontFamily: { value: "Arial, sans-serif" },
        // padding: { value: ".5em" },
        padding: { value: "1rem" },
        borderRadius: { value: "8px" },
        boxShadow: { value: "0 5px 5px rgba(9, 10, .5, 0.5)" },
        // margin: { value: "large" },
      },
      input: {
        color: { value: "#333" },
        backgroundColor: { value: "green" },
        borderColor: { value: "#333" },
        borderRadius: { value: "10px" },
        padding: { value: ".5em" },
        margin: { value: "1em 0 !important" },
      },
      button: {
        primary: {
          backgroundColor: { value: "hsl(210deg 29.03% 24.31%)" },
        },
        color: { value: "#fff" },
        backgroundColor: { value: "hsl(210deg 29.03% 24.31%)" },
        borderRadius: { value: "4px" },
        padding: { value: ".5em" },
        margin: { value: ".5em" },
      },
      menu: {
        backgroundColor: { value: "hsl(210deg 29.03% 24.31%)" },
      },
    },
  },
});

const NoMatch = ({ location }) => (
  <ThemeProvider colorMode="dark" theme={customTheme}>
    {/* <View marginLeft={"auto"} marginRight={"auto"}> */}
    <View>
      <Card textAlign={"center"}>
        <Heading marginTop={"5em"} level={5}>How'd you get here?</Heading>
        <Button marginTop={"2em"} marginBottom={"5em"}
          onClick={() => {
            // return (<Redirect to={"/search"}/>)
            window.location.href = "/";
          }}
        >
          Go Home
        </Button>
      </Card>
    </View>
  </ThemeProvider>
);

PrivateRoute = withRouter(PrivateRoute);

const history = require("history").createBrowserHistory();

const Routes = (props) => (
  <Router>
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <ThemeProvider colorMode="dark" theme={customTheme}>
        <Header {...props} />
        <MainNavBar {...props} />
        <div
          className="main-content"
          style={{
            // backgroundColor: "#3d5f81 !important",
            // backgroundColor: "rgb(44, 62, 80)",
            marginLeft: "auto",
            marginRight: "auto",
            // background: "white",
            minHeight: "100vh",
            maxWidth: "800px",
          }}
        >
          <AlertModule />
          {/* <AlertModule children={(<><Heading>HEY</Heading></>)} okButtonText="test"/> */}
          <Switch id="switch">
            <Route path="/auth" props={props} exact component={AuthModal} />
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={ContactUs} />
            <Route exact path="/about" component={AboutUs} />
            <Route exact path="/privacy" component={PrivacyPolicy}/>
            <PrivateRoute exact path="/search" component={SearchListings} />
            <PrivateRoute
              exact
              path="/setcommission"
              component={SetCommission}
            />
            <PrivateRoute exact path="/mylistings" component={MyListings} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/pricing" component={ProductInfo} />
            <Route component={NoMatch} />
          </Switch>
        </div>
        <Footer />
      </ThemeProvider>
    </div>
  </Router>
);

// const styles = {
//   mainContent: {
//     maxWidth: "800px",
//     marginLeft: "auto !important",
//     marginRight: "auto !important",
//     background: "#3d5f81 !important", //#3d5f81
//     minHeight: "100vh",
//   },
// };

export default Routes;
