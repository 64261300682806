/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { useRef } from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
  Alert,
  Text,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { API } from "aws-amplify";
import { createListing } from "../graphql/mutations";
import AddressSearch from "../components/AddressSearch";
export default function ListingCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    // broker: props.user.attributes["custom:brokerage"] ?? "",
    // agent: props.user.attributes["custom:mlsNumber"] ?? "",
    // address: "",
    // mlsnumber: "",
    // commissionType: props.user.attributes["custom:commissionType"] ?? "",
    // commission: props.user.attributes["custom:commission"] ?? "",
    // isActive: true,
    // transCoordCommissionType:
    //   props.user.attributes["custom:commissionType"] ?? "",
    broker: props.overrides?.initialValues?.broker ?? "",
    agent: props.overrides?.initialValues?.agent ?? "",
    address: "",
    mlsnumber: "",
    commissionType: props.overrides?.initialValues?.commissionType ?? "",
    commission: props.overrides?.initialValues?.commission ?? "",
    transCoordCommissionType: "Flat Fee",
    transCoordCommission: 0,
    isActive: true,
  };
  const [broker, setBroker] = React.useState(initialValues.broker);
  const [agent, setAgent] = React.useState(initialValues.agent);
  const [address, setAddress] = React.useState(initialValues.address);
  const [mlsnumber, setMlsnumber] = React.useState(initialValues.mlsnumber);
  const [commissionType, setCommissionType] = React.useState(
    initialValues.commissionType
  );
  const [commission, setCommission] = React.useState(initialValues.commission);
  const [
    transCoordCommissionType,
    setTransCoordCommissionType,
  ] = React.useState(initialValues.transCoordCommissionType);
  const [transCoordCommission, setTransCoordCommission] = React.useState(
    initialValues.transCoordCommission
  );
  const [isActive, setIsActive] = React.useState(initialValues.isActive);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBroker(initialValues.broker);
    setAgent(initialValues.agent);
    setAddress(initialValues.address);
    setMlsnumber(initialValues.mlsnumber);
    setCommissionType(initialValues.commissionType);
    setCommission(initialValues.commission);
    setTransCoordCommissionType(initialValues.transCoordCommissionType);
    setTransCoordCommission(initialValues.transCoordCommission);
    setIsActive(initialValues.isActive);
    setErrors({});
  };
  const validations = {
    broker: [],
    agent: [{ type: "Required" }],
    address: [{ type: "Required" }],
    mlsnumber: [],
    commissionType: [{ type: "Required" }],
    commission: [{ type: "Required" }],
    transCoordCommissionType: [],
    transCoordCommission: [],
    isActive: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const topRef = useRef(0,0);
  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          broker,
          agent,
          address,
          mlsnumber,
          commissionType,
          commission,
          transCoordCommissionType,
          transCoordCommission,
          isActive,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          scrollToTop();
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: createListing.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ListingCreateForm")}
      {...rest}
        >
      {errors && Object.keys(errors)?.some(key => errors[key]?.hasError) && (
        <Alert variation="error" ref={topRef}>
          {scroll()}
          <ul>
        {Object.entries(errors).map(([key, value]) => (
          value?.hasError && <li key={key}>{value?.errorMessage?.replaceAll("The value", key)}</li>
        ))}
          </ul>
        </Alert>
      )}
      <TextField
        label="Brokerage"
        isRequired={false}
        isReadOnly={false}
        value={broker}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              broker: value,
              agent,
              address,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.broker ?? value;
          }
          if (errors.broker?.hasError) {
            runValidationTasks("broker", value);
          }
          setBroker(value);
        }}
        onBlur={() => runValidationTasks("broker", broker)}
        errorMessage={errors.broker?.errorMessage}
        hasError={errors.broker?.hasError}
        {...getOverrideProps(overrides, "broker")}
      ></TextField>
      <TextField
        label="Agent"
        isRequired={false}
        isReadOnly={false}
        value={agent}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              broker,
              agent: value,
              address,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.agent ?? value;
          }
          if (errors.agent?.hasError) {
            runValidationTasks("agent", value);
          }
          setAgent(value);
        }}
        onBlur={() => runValidationTasks("agent", agent)}
        errorMessage={errors.agent?.errorMessage}
        hasError={errors.agent?.hasError}
        {...getOverrideProps(overrides, "agent")}
      ></TextField>
      <AddressSearch
        label="Address"
        isRequired={true}
        isReadOnly={false}
        value={address}
        onAddressChange={(address) => {
          let value = address;
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address: value,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></AddressSearch>
      <TextField
        label="Listing ID"
        isRequired={false}
        isReadOnly={false}
        value={mlsnumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber: value,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.mlsnumber ?? value;
          }
          if (errors.mlsnumber?.hasError) {
            runValidationTasks("mlsnumber", value);
          }
          setMlsnumber(value);
        }}
        onBlur={() => runValidationTasks("mlsnumber", mlsnumber)}
        errorMessage={errors.mlsnumber?.errorMessage}
        hasError={errors.mlsnumber?.hasError}
        {...getOverrideProps(overrides, "mlsnumber")}
      ></TextField>
      <SelectField
        label="Commission Type"
        placeholder="Please select an option"
        isDisabled={false}
        value={commissionType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber,
              commissionType: value,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.commissionType ?? value;
          }
          if (errors.commissionType?.hasError) {
            runValidationTasks("commissionType", value);
          }
          setCommissionType(value);
        }}
        onBlur={() => runValidationTasks("commissionType", commissionType)}
        errorMessage={errors.commissionType?.errorMessage}
        hasError={errors.commissionType?.hasError}
        {...getOverrideProps(overrides, "commissionType")}
      >
        <option
          children="Flat Fee"
          value="Flat Fee"
          {...getOverrideProps(overrides, "commissionTypeoption1")}
        ></option>
        <option
          children="Percentage"
          value="Percentage"
          {...getOverrideProps(overrides, "commissionTypeoption0")}
        ></option>
      </SelectField>
      <TextField
        label="Commission"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={commission}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber,
              commissionType,
              commission: value,
              transCoordCommissionType,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.commission ?? value;
          }
          if (errors.commission?.hasError) {
            runValidationTasks("commission", value);
          }
          setCommission(value);
        }}
        onBlur={() => runValidationTasks("commission", commission)}
        errorMessage={errors.commission?.errorMessage}
        hasError={errors.commission?.hasError}
        {...getOverrideProps(overrides, "commission")}
      ></TextField>
      <SelectField
        label="Trans Coord Commission Type"
        placeholder="Please select an option"
        isDisabled={false}
        value={transCoordCommissionType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType: value,
              transCoordCommission,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.transCoordCommissionType ?? value;
          }
          if (errors.transCoordCommissionType?.hasError) {
            runValidationTasks("transCoordCommissionType", value);
          }
          setTransCoordCommissionType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "transCoordCommissionType",
            transCoordCommissionType
          )
        }
        errorMessage={errors.transCoordCommissionType?.errorMessage}
        hasError={errors.transCoordCommissionType?.hasError}
        {...getOverrideProps(overrides, "transCoordCommissionType")}
      >
        <option
          children="Percentage"
          value="Percentage"
          {...getOverrideProps(overrides, "transCoordCommissionTypeoption0")}
        ></option>
        <option
          children="Flat Fee"
          value="Flat Fee"
          {...getOverrideProps(overrides, "transCoordCommissionTypeoption1")}
        ></option>
      </SelectField>
      <TextField
        label="Trans Coord Commission"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={transCoordCommission}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission: value,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.transCoordCommission ?? value;
          }
          if (errors.transCoordCommission?.hasError) {
            runValidationTasks("transCoordCommission", value);
          }
          setTransCoordCommission(value);
        }}
        onBlur={() =>
          runValidationTasks("transCoordCommission", transCoordCommission)
        }
        errorMessage={errors.transCoordCommission?.errorMessage}
        hasError={errors.transCoordCommission?.hasError}
        {...getOverrideProps(overrides, "transCoordCommission")}
      ></TextField>
      <SwitchField
        label="Active"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isActive}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              broker,
              agent,
              address,
              mlsnumber,
              commissionType,
              commission,
              transCoordCommissionType,
              transCoordCommission,
              isActive: value,
            };
            const result = onChange(modelFields);
            value = result?.isActive ?? value;
          }
          if (errors.isActive?.hasError) {
            runValidationTasks("isActive", value);
          }
          setIsActive(value);
        }}
        onBlur={() => runValidationTasks("isActive", isActive)}
        errorMessage={errors.isActive?.errorMessage}
        hasError={errors.isActive?.hasError}
        {...getOverrideProps(overrides, "isActive")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}