/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
export default function MyProfile(props) {
  const { onSubmit, onValidate, onChange, overrides, ...rest } = props;
  const { tokens } = useTheme();
  const initialValues = {
    email: props.overrides.initialValues?.email ?? "",
    Name: props.overrides.initialValues?.Name ?? "",
    AgentId: props.overrides.initialValues?.AgentId ?? "",
    Brokerage: props.overrides.initialValues?.Brokerage ?? "",
    phoneNumber: (props.overrides.initialValues?.phoneNumber ?? "").replace(
      /^\+1/,
      ""
    ),
    CommissionType: props.overrides.initialValues?.CommissionType ?? "",
    defaultCommission: props.overrides.initialValues?.defaultCommission ?? "",
  };
  const [email, setEmail] = React.useState(initialValues.email);
  const [Name, setName] = React.useState(initialValues.Name);
  const [AgentId, setAgentId] = React.useState(initialValues.AgentId);
  const [Brokerage, setBrokerage] = React.useState(initialValues.Brokerage);
  const [phoneNumber, setPhoneNumber] = React.useState(
    initialValues.phoneNumber
  );
  const [CommissionType, setCommissionType] = React.useState(
    initialValues.CommissionType
  );
  const [defaultCommission, setDefaultCommission] = React.useState(
    initialValues.defaultCommission
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setEmail(initialValues.email);
    setName(initialValues.Name);
    setAgentId(initialValues.AgentId);
    setBrokerage(initialValues.Brokerage);
    setPhoneNumber(initialValues.phoneNumber);
    setCommissionType(initialValues.CommissionType);
    setDefaultCommission(initialValues.defaultCommission);
    setErrors({});
  };
  const validations = {
    email: [{ type: "Required" }],
    Name: [{ type: "Required" }],
    AgentId: [],
    Brokerage: [],
    phoneNumber: [{ type: "Phone" }],
    CommissionType: [],
    defaultCommission: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding={tokens.space.xs.value}
      onSubmit={async (event) => {
        event.preventDefault();
        const modelFields = {
          email,
          Name,
          AgentId,
          Brokerage,
          phoneNumber,
          CommissionType,
          defaultCommission,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        await onSubmit(modelFields);
      }}
      {...getOverrideProps(overrides, "MyProfile")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Email</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email: value,
              Name,
              AgentId,
              Brokerage,
              phoneNumber,
              CommissionType,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Name</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        value={Name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name: value,
              AgentId,
              Brokerage,
              phoneNumber,
              CommissionType,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.Name ?? value;
          }
          if (errors.Name?.hasError) {
            runValidationTasks("Name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("Name", Name)}
        errorMessage={errors.Name?.errorMessage}
        hasError={errors.Name?.hasError}
        {...getOverrideProps(overrides, "Name")}
      ></TextField>
      <TextField
        label="Agent ID"
        value={AgentId}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name,
              AgentId: value,
              Brokerage,
              phoneNumber,
              CommissionType,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.AgentId ?? value;
          }
          if (errors.AgentId?.hasError) {
            runValidationTasks("AgentId", value);
          }
          setAgentId(value);
        }}
        onBlur={() => runValidationTasks("AgentId", AgentId)}
        errorMessage={errors.AgentId?.errorMessage}
        hasError={errors.AgentId?.hasError}
        {...getOverrideProps(overrides, "AgentId")}
      ></TextField>
      <TextField
        label="Brokerage"
        value={Brokerage}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name,
              AgentId,
              Brokerage: value,
              phoneNumber,
              CommissionType,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.Brokerage ?? value;
          }
          if (errors.Brokerage?.hasError) {
            runValidationTasks("Brokerage", value);
          }
          setBrokerage(value);
        }}
        onBlur={() => runValidationTasks("Brokerage", Brokerage)}
        errorMessage={errors.Brokerage?.errorMessage}
        hasError={errors.Brokerage?.hasError}
        {...getOverrideProps(overrides, "Brokerage")}
      ></TextField>
      <TextField
        label="Phone Number"
        isRequired={false}
        type="tel"
        value={phoneNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name,
              AgentId,
              Brokerage,
              phoneNumber: value,
              CommissionType,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.phoneNumber ?? value;
          }
          if (errors.phoneNumber?.hasError) {
            runValidationTasks("phoneNumber", value);
          }
          setPhoneNumber(value);
        }}
        onBlur={() => runValidationTasks("phoneNumber", phoneNumber)}
        errorMessage={errors.phoneNumber?.errorMessage}
        hasError={errors.phoneNumber?.hasError}
        {...getOverrideProps(overrides, "phoneNumber")}
      ></TextField>
      <SelectField
        label="Default Commission Type"
        placeholder="Please select an option"
        value={CommissionType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name,
              AgentId,
              Brokerage,
              phoneNumber,
              CommissionType: value,
              defaultCommission,
            };
            const result = onChange(modelFields);
            value = result?.CommissionType ?? value;
          }
          if (errors.CommissionType?.hasError) {
            runValidationTasks("CommissionType", value);
          }
          setCommissionType(value);
        }}
        onBlur={() => runValidationTasks("CommissionType", CommissionType)}
        errorMessage={errors.CommissionType?.errorMessage}
        hasError={errors.CommissionType?.hasError}
        {...getOverrideProps(overrides, "CommissionType")}
      >
        <option
          children="$"
          value="$"
          {...getOverrideProps(overrides, "CommissionTypeoption0")}
        ></option>
        <option
          children="%"
          value="%"
          {...getOverrideProps(overrides, "CommissionTypeoption1")}
        ></option>
      </SelectField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Default Commission</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        type="number"
        step="any"
        value={defaultCommission}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              Name,
              AgentId,
              Brokerage,
              phoneNumber,
              CommissionType,
              defaultCommission: value,
            };
            const result = onChange(modelFields);
            value = result?.defaultCommission ?? value;
          }
          if (errors.defaultCommission?.hasError) {
            runValidationTasks("defaultCommission", value);
          }
          setDefaultCommission(value);
        }}
        onBlur={() =>
          runValidationTasks("defaultCommission", defaultCommission)
        }
        errorMessage={errors.defaultCommission?.errorMessage}
        hasError={errors.defaultCommission?.hasError}
        {...getOverrideProps(overrides, "defaultCommission")}
      ></TextField>
      <Flex
        justifyContent="flex-end"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
