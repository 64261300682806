import React from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js"; // Import the CognitoUserAttribute type
import {
  View,
  Card,
  Flex,
  Alert,
  AlertVariations,
  Text,
} from "@aws-amplify/ui-react";
import { Heading, AccountSettings } from "@aws-amplify/ui-react";
import MyProfile from "./ui-components/MyProfile";
import { MyProfileInputValues } from "./ui-components/MyProfile";
import { Auth } from "aws-amplify";
// import { loadStripe } from "@stripe/stripe-js";
import { getErrorMessage } from "./ui-components/utils";
// If SetupTOTP is indeed a component but not exported directly, try importing it correctly
// For example, if SetupTOTP is part of a named export:
import { Authenticator } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";

interface ProfProps {
  user: any;
  history: any;
  location: any;
  match: any;
  props: any;
  staticContext: any;
}

const Profile: React.FC<ProfProps> = (userParam) => {
  const [statusMessage, setStatusMessage] = React.useState<
    string | JSX.Element
  >("");
  const [messageType, setMessageType] = React.useState("info");
  const handleUpdateUserAttributes = async (
    updatedFields: MyProfileInputValues
  ) => {
    try {
      const currentUser = userParam.user; //await Auth.currentAuthenticatedUser();
      const updatedAttributes: CognitoUserAttribute[] = [];

      // if updatedFields.phonenumber is not correct, add the +1 to make it a proper AWS Phone Number
      if (updatedFields.phoneNumber) {
        updatedFields.phoneNumber = updatedFields.phoneNumber.startsWith("+1")
          ? updatedFields.phoneNumber
          : `+1${updatedFields.phoneNumber}`;
      }

      // Update the attributes with the new values
      if (updatedFields.email) {
        updatedAttributes.push({
          Name: "email",
          Value: updatedFields.email,
        } as CognitoUserAttribute);
      }
      if (updatedFields.Name) {
        updatedAttributes.push({
          Name: "name",
          Value: updatedFields.Name,
        } as CognitoUserAttribute);
      }
      if (updatedFields.phoneNumber) {
        updatedAttributes.push({
          Name: "phone_number",
          Value: updatedFields?.phoneNumber?.startsWith("+1")
            ? updatedFields?.phoneNumber
            : `+1${updatedFields.phoneNumber}`,
        } as CognitoUserAttribute);
      }
      if (updatedFields.AgentId) {
        updatedAttributes.push({
          Name: "custom:mlsNumber",
          Value: updatedFields.AgentId,
        } as CognitoUserAttribute);
      }
      if (updatedFields.Brokerage) {
        updatedAttributes.push({
          Name: "custom:brokerage",
          Value: updatedFields.Brokerage,
        } as CognitoUserAttribute);
      }
      if (updatedFields.CommissionType) {
        updatedAttributes.push({
          Name: "custom:commissionType",
          Value: updatedFields.CommissionType,
        } as CognitoUserAttribute);
      }
      if (updatedFields.defaultCommission) {
        updatedAttributes.push({
          Name: "custom:commission",
          Value: updatedFields.defaultCommission,
        } as CognitoUserAttribute);
      }

      // Update the user attributes
      const response = await Auth.updateUserAttributes(
        currentUser,
        updatedFields
      );

      if (response === "SUCCESS") {
        setStatusMessage("Your profile was updated! ");
        setMessageType("success");
      } else {
        const errMessage = getErrorMessage(response);
        setStatusMessage("Something went wrong. " + errMessage);
        setMessageType("error");
      }
    } catch (error) {
      const errMessage = getErrorMessage(error);
      setStatusMessage(errMessage);
      setMessageType("error");
      // console.error(":", error);
    }
  };
  const helmet = (
    <Helmet>
      <title>My Profile</title>
      <meta
        name="description"
        content="This is your profile page. Update your information here."
      />
      <meta
        name="keywords"
        content="cooperating broker, profile, account settings, user information, user profile, user account, user settings"
      />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="My Profile" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://www.cooperating-broker.com/profile" />
      <meta
        property="og:description"
        content="This is your profile page. Update your information here."
      />
      <meta
        property="og:url"
        content="https://www.cooperating-broker.com/profile"
      />
    </Helmet>
  );
  return (
    <View>
      <Card style={styles.container}>
        {/* <Card marginBottom="2em"> */}
        {statusMessage && (
          <Alert variation={messageType as AlertVariations}>
            <Text>{statusMessage}</Text>
          </Alert>
        )}
        {/* </Card> */}
        {/* <Button
        onClick={handleClick}
        >
          Sign Up
        </Button> */}
        {/* <Divider /> */}

        <Heading level={4} textAlign={"center"}>
          My Profile
        </Heading>
        {/* <Subscription isLocalHost /> */}

        <MyProfile
          // marginBottom={"2em"}
          // {...userParam.user.attributes}
          // {...routerProps}
          overrides={{
            phoneNumber: {
              onInvalid: (e) => {
                const phoneNumber = (e.target as HTMLInputElement).value;
                if (phoneNumber.length !== 12) {
                  return "Please enter a valid phone number.";
                }
                return "Please enter a valid phone number.";
              },
              formNoValidate: true,
              label: "Phone Number",
              placeholder: "Phone Number",
              onError: () =>
                "Ignore the `+1` country code, we'll add it for you.",
              // onBlur: (e) => {
              //   const phoneNumber = (e.target as HTMLInputElement).value;
              //   if (phoneNumber.length === 10) {
              //     const fullNumber = `+1${phoneNumber}`;
              //     (e.target as HTMLInputElement).value = fullNumber;
              //     // setPhoneNumber(fullNumber);
              //     return fullNumber;
              //     // (e.target as HTMLInputElement).value = `+1${phoneNumber}`;
              //   }
              //   // alert(e);
              // },

              // onChange: (e) => {
              //   const phoneNumber = (e.target as HTMLInputElement).value;
              //   if (phoneNumber.length === 10) {
              //     const fullNumber = `+1${phoneNumber}`;
              //     // setPhoneNumber(fullNumber);
              //     return fullNumber;
              //     // (e.target as HTMLInputElement).value = `+1${phoneNumber}`;
              //   }
              //   return phoneNumber;
              // }
            },
            cancelButton: {
              display: "none",
            },
            initialValues: {
              email: userParam.user.attributes.email,
              Name: userParam.user.attributes.name,
              AgentId: userParam.user.attributes["custom:mlsNumber"],
              Brokerage: userParam.user.attributes["custom:brokerage"],
              CommissionType:
                userParam.user.attributes["custom:commissionType"],
              defaultCommission: userParam.user.attributes["custom:commission"],
              phoneNumber: userParam.user.attributes.phone_number,
            },
            // CommissionType: {
            //   options: ["Flat Fee", "Percentage"],
            // },
            email: {
              "aria-disabled": true,
              descriptiveText: "Your email address cannot be changed.",
              readOnly: true,
              display: "none",
            },
            commissionType: {
              required: true,
            },
          }}
          onSubmit={(fields) => {
            const fullNumber = fields.phoneNumber
              ? fields.phoneNumber.startsWith("+1")
                ? fields.phoneNumber
                : `+1${fields.phoneNumber}`
              : "";
            let updatedFields = {
              email: fields.email,
              name: fields.Name,
              "custom:mlsNumber": fields.AgentId,
              "custom:brokerage": fields.Brokerage,
              "custom:commissionType": fields.CommissionType,
              "custom:commission": fields.defaultCommission,
              phone_number: fullNumber,
            };

            handleUpdateUserAttributes(updatedFields);
            window.scrollTo(0, 0);
            return updatedFields;
          }}
          // onSuccess={() => {
          // }}
        />
      </Card>
      {/* </Flex> */}
    </View>
  );
  // }
};
const styles = {
  container: {
    marginBottom: "10%",
    // display: "table",
    // height: "100%",
    // width: "100%",
  },
};
export default Profile;
