import React from "react";
import { Card, Flex, Heading, Text, View } from "@aws-amplify/ui-react";
import { CTASection, SocialPost } from "./ui-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface AboutUsProps {
  // Define your component props here
}

const AboutUs: React.FC<AboutUsProps> = () => {
  // Add your component logic here
  return (
    <View>
      <Helmet>
        <title>About Us</title>
        <meta
          name="google-adsense-account"
          content="ca-pub-9591636847579215"
        ></meta>
        <meta name="description" content="About 'Cooperating Broker' app." />
        <meta name="author" content="Cooperating Broker" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="About Us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="About 'Cooperating Broker' app."
        />
        <meta
          property="og:url"
          content="https://www.cooperating-broker.com/about"
        />
        <meta
          name="keywords"
          content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission"
        />
      </Helmet>

      <Card style={styles.tabContent}>
        <Heading textAlign={"center"} level={3}>
          Why Now?
        </Heading>
        <Card>
          <Text>
            The recent National Association of Realtors (NAR) settlement
            includes changes to the rules regarding the advertising of broker
            commissions for properties.
          </Text>
          <br />
          <Text>
            The new rule prohibits the public display of the commission offered
            to buyer brokers, aiming to increase transparency and competition in
            the real estate market. This change has led to challenges for
            listing agents in effectively communicating commission information
            to buyer agents.
          </Text>
          <br />⏩{" "}
          <a href="https://facts.realtor" target="_blank">
            NAR Realtor Facts
          </a>
        </Card>
        {/* <Flex>
        <SocialPost />
        </Flex> */}
        <Card>
          <Heading margin={"0 0 1em 0"} textAlign={"center"} level={3}>
            What's that mean to me?
          </Heading>
          <Text>
            It means MLS sites such as Zillow and ShowingTime aren't acceptable
            platforms to display commission information --- leading to a lack of
            transparency, confusion, and countless repetitive phone calls.
            {/* Listing agents need a new way to communicate commission information to buyer agents. */}
          </Text>
          <br />
          <Text>
            <strong>Cooperating Broker</strong> is here to help listing agents
            navigate this new landscape by providing a platform to easily input
            and advertise the commission they are willing to pay to buyer
            brokers. Our app ensures that buyer agents have clear and upfront
            information about commission agreements, allowing for smoother
            transactions and a more efficient process for all parties involved.
          </Text>
        </Card>
        <Card>
          <Text>
            Stay ahead of the curve with <strong>Cooperating Broker</strong> and
            make sure your listings are getting the attention they deserve in
            this evolving real estate market. <Link to="/profile">Sign up</Link>{" "}
            for Cooperating Broker today and simplify your real estate
            transactions.
          </Text>
        </Card>
      </Card>
      {/* <Flex style={styles.container}>
        <CTASection 
          title="Ready to get started?"
          // overrides={styles}
          // subtitle="Sign up for Cooperating Broker today and simplify your real estate transactions."
        />
      </Flex> */}
    </View>
  );
};
const styles = {
  buttonContainer: {
    display: "flex",
    flexdirection: "column",
    alignitems: "center",
  },
  button: {
    cursor: "pointer",
    margin: 4,
    border: "1px solid #ddd",
    width: 225,
    ":hover": {
      backgroundcolor: "rgba(0, 0, 0, .1)",
    },
  },
  input: {
    padding: 8,
    height: 40,
    width: 225,
    border: "1px solid #ddd",
  },
  miniButton: {
    padding: 5,
    margin: 0,
  },
  submitButton: {
    padding: "10px 20px",
    margin: "10px 0px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "hsl(210, 50%, 10%)",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease 0s",
  },
  footer: {
    backgroundColor: "hsl(210, 50%, 10%)",
    color: "white",
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "flex",
    textAlign: "start",
  },
  container: {
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    color: "#333",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#0d1a26",
    color: "white",
    // position: "sticky",
    width: "100%",
    top: 0,
    display: "flex",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
  headerImage: {
    height: "100px",
    verticalAlign: "middle",
  },
  headerTitle: {
    display: "inline",
    verticalAlign: "middle",
    fontSize: "30px",
    margin: 0,
    textAlign: "center",
    width: "100%",
  },
  navbar: {
    display: "flex",
    backgroundColor: "rgb(44, 62, 80)",
    padding: "10px 0",
    justifyContent: "center",
    width: "100%",
    top: "0",
    position: "sticky",
  },
  tabLink: {
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "inherit",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    ":hover": {
      backgroundColor: "#e67e22",
    },
  },
  tabContent: {
    display: "inline-block",
    padding: "20px",
    background: "white",
    color: "#0d1a26",
    fontSize: "1.2em",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s, box-shadow 0.3s",
    marginBottom: "10%",
    h3: {
      color: "#333",
      fontSize: "2em",
      marginBottom: "10px",
      marginTop: 0,
    },
    p: {
      // textAlign: "justify",
      lineHeight: 1.6,
      // marginTop: "1em",
      p: {
        padding: "10px 0",
      },
    },
  },
  footerContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
};
export default AboutUs;
