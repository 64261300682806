import React from "react";
import { Link } from "react-router-dom";
import UserContext from "../UserContext";
import {
  Flex,
  Menu,
  MenuItem,
  Heading,
  Grid,
  Card,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import { ContactUs } from "../ui-components";

const Footer = () => {
  return (
    <Card {...styles.footerContainer}>
      <div>
        {/* <Text> */}© 2024 Cooperating Broker. All rights reserved.
        {/* </Text> */}
      </div>
    </Card>
  );
};

const styles = {
  submitButton: {
    padding: "10px 20px",
    margin: "10px 0px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "hsl(210, 50%, 10%)",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease 0s",
  },
  footerContainer: {
    backgroundColor: "rgb(28 40 52)",
    color: "white",
    position: "sticky",
    width: "100%",
    textAlign: "center",
    bottom: "0px",
    // marginTop: "2em",
  },
  // footerContainer: {
  // },
};

export default Footer;
