"use client";

import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Card,
  Heading,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View,
  Text,
  Loader,
} from "@aws-amplify/ui-react";
import { getListing, listListings } from "./graphql/queries";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import ListingDetails from "./ListingDetails";
import { Listing } from "./models";
import { Helmet } from "react-helmet";

async function loadInitialListings() {
  try {
    const listingData: any = await API.graphql(graphqlOperation(listListings));
    const listings = listingData.data.listListings.items;
    return listings;
  } catch (err) {
  }
}

const SearchListings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<Listing>(
    {} as Listing
  );
  const [showListingDetails, setShowListingDetails] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const initialListings = loadInitialListings().then((listings) => {
    return listings.filter((l: Listing) => {
      return l.isActive === true;
    });
  });
  useEffect(() => {
    loadInitialListings().then((listings) => {
      setListings(listings.filter((l: Listing) => {
        return l.isActive === true;
      }));
      // setListings([]);
      setIsLoading(false);
    });
  }, []);

  const handleClick = async (l: Listing) => {
    try {
      window.scrollTo(0, 0);
      // const listingData: any = await API.graphql(
      //   graphqlOperation(getListing, { address: l.address })
      // );
      const listingData = listings.filter((listing) => {
        return listing.address === l.address;
      });
      const theListing = listingData[0] ?? null;
      setSelectedProperty(theListing);
      setShowListingDetails(true);
      // setIsLoading(false);
    } catch (err) {
      // console.log("Uh oh.", err);
    }
  };
  async function fetchListings(e: any) {
    try {
      if (e) {
        setSearchTerm(e.target.value);
        const filteredListings = listings.filter((l: Listing) => {
          return l.address
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        setListings(filteredListings.filter((l: Listing) => {
          return l.isActive === true;
        }));
      }
      if (e.target.value.trim() === "" || searchTerm === undefined) {
        // setListings([]);
        setListings(
          await initialListings.then((listings) => {
            return listings;
          })
        );
      }
    } catch (err) {
      setSearchTerm("");
      // console.log("Uh oh.", err);
    }
  }

  const helmet = (
    <Helmet>
      <title>Search Cooperating Broker</title>
      <meta name="description" content="About 'Cooperating Broker' app." />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Cooperating Broker" />
      <meta property="og:title" content="Find Commission" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Find the commission for a listing." />
      <meta property="og:url" content="https://www.cooperating-broker.com/search" />
      <meta
        name="keywords"
        content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission"
      />
    </Helmet>
  );

  return (
    isLoading ? (
      <Loader
        variation="linear"
        emptyColor={"hsl(210deg 29.03% 24.31%)"}
        filledColor={"white"}
      />
    ) : (
      <div>
        {helmet}
        {showListingDetails ? (
          <Card>
            {/* <Card> */}
            <ListingDetails
              onClose={() => setShowListingDetails(false)}
              listing={selectedProperty}
            // onClose={() => setShowListingDetails(false)}
            />
            {/* </Card> */}
          </Card>
        ) : (
          <View>

            <Card>
              <Card>
                <Heading level={4} textAlign={"center"}>
                  Search Listings
                </Heading>
                <Input
                  marginTop={"1em"}
                  variation="quiet"
                  // borderStyle={"inset"}
                  id="searchField"
                  tabIndex={0}
                  key={"search"}
                  type="text"
                  value={searchTerm}
                  onChange={fetchListings}
                  placeholder="Search listings"
                />
              </Card>
              {listings && listings.length > 0 ? (
                <Table textAlign={"left"} borderColor={"rgb(44, 62, 80)"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      {/* <TableCell>Broker</TableCell> */}
                      {/* <TableCell>Agent</TableCell> */}
                      <TableCell>Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listings.map((property, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleClick(property)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{property.address}</TableCell>
                        {/* <TableCell>{l.broker}</TableCell> */}
                        {/* <TableCell>{l.agent}</TableCell> */}
                        {/* <TableCell>{l.commission}</TableCell> */}
                        <TableCell>
                          {property.commissionType === "Flat Fee" ? "$" : ""}
                          {property.commission}
                          {property.commissionType === "Percentage" ? "%" : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                // <Alert variation={"warning"}>
                <Card textAlign={"center"}>
                  <Text>
                    {searchTerm
                      ? `No listings found for "${searchTerm}"`
                      : "Begin typing to search..."}
                  </Text>
                </Card>
                // </Alert>
              )}
            </Card>
          </View>
        )}
      </div>
    )
  );
};

export default SearchListings;
