import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { register } from "./registerServiceWorker";
import config from "./aws-exports";
import { Amplify } from "aws-amplify";
import "./App.css";
import { initGA, logPageView } from './services/analytics';
import initVitals from './services/reportWebVitals';

initGA();
logPageView();
initVitals();

Amplify.configure(config);

ReactDOM.render(
  <>
    {process.env.NODE_ENV === "development" ? (
      <StrictMode>
        <App key={"CooperatingBroker"} />
      </StrictMode>
    ) : (
      <App key={"CooperatingBroker"} />
    )}
  </>,
  document.getElementById("root")
);
register();

// reportWebVitals();
