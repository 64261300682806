import React, { useEffect } from "react";
import {
  Alert,
  Authenticator,
  Button,
  Card,
  Heading,
  SelectField,
  ThemeProvider,
  View,
} from "@aws-amplify/ui-react";
import { CTASection } from "./ui-components";
import Subscription from "./components/Subscription";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
const AuthModal: React.FC = (props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  // const userStatus = async () => {
  //   await Auth.currentAuthenticatedUser().then((user: Promise<CognitoUser | any>) => {
  //     user.then((user: CognitoUser) => {
  //       console.log("User authenticated", user);
  //       setIsLoggedIn(true);
  //     }).catch((error: any) => {
  //       console.error("User not authenticated", error);
  //     });
  //     return user;
  //   });
  //   return userStatus;
  // };

  // useEffect(() => {
  //   userStatus();
  // }, []);

  // return <Redirect to="/dashboard" />;
  // .catch((error: any) => {
  //   console.error("User not authenticated", error);
  // });
  const vars = JSON.stringify(process.env);
  return (
    <View>
      {/* <Alert variation="info">{vars}</Alert> */}
      {/* <Heading>You're logged in</Heading> */}
      <Authenticator
        // variation="modal"
        loginMechanisms={["email"]}
        signUpAttributes={["name", "email", "phone_number"]}
        formFields={{
          signIn: {},
          signUp: {
            "custom:brokerage": {
              type: "custom:brokerage",
              label: "Brokerage",
              placeholder: "Enter your brokerage",
              required: true,
            },
            "custom:mlsNumber": {
              type: "custom:mlsNumber",
              label: "Agent ID",
              placeholder: "Enter your Agent ID",
              required: true,
            },
          },
        }}
      >
        <div>
          {/* <CTASection /> */}
          {/* <Subscription isLocalHost /> */}
          <ThemeProvider colorMode="dark">
            {/* <View marginLeft={"auto"} marginRight={"auto"}> */}
            <View>
              <Card textAlign={"center"}>
                <Heading marginTop={"5em"} level={5}>
                  You're logged in already 🤔
                </Heading>
                <Button
                  marginTop={"2em"}
                  marginBottom={"5em"}
                  onClick={() => {
                    // return (<Redirect to={"/search"}/>)
                    window.location.href = "/";
                  }}
                >
                  Go Home
                </Button>
              </Card>
            </View>
          </ThemeProvider>
        </div>
      </Authenticator>
    </View>
  );
};

export default AuthModal;
