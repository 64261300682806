/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://etzetzdtx5hljfo3ckgbnbdeae.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-72zqazuhvzgrze5hmayyjsyzbi",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe",
            "endpoint": "https://2w0e0s3gsf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:2bba545c-6421-4312-80b3-e4c74cf025c3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jIS6jkXcS",
    "aws_user_pools_web_client_id": "69c1m8a2c5s6jvrptt2mb83ad3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cbroker-contracts-bucket97c51-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "search_indices": {
                "items": [
                    "addresslookup-dev"
                ],
                "default": "addresslookup-dev"
            }
        }
    }
};


export default awsmobile;
