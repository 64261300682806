import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { listListings } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { Listing } from "../models";
import { deleteListing } from "../graphql/mutations";
class ListingsUtils {
  async getListings() {
    try {
      const response: GraphQLResult<any> = await API.graphql({
        query: listListings,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching listings:", error);
      throw error;
    }
  }

  async createListing(listingData: Listing) {
    try {
      const response = await API.graphql({
        query: mutations.createListing,
        variables: { input: listingData },
      });
      if ("data" in response) {
        return response.data.createListing;
      } else {
        throw new Error("Invalid response");
      }
    } catch (error) {
      console.error("Error creating listing:", error);
      throw error;
    }
  }

  async updateListing(listingId: string, listingData: Listing) {
    try {
      const response: GraphQLResult<any> = await API.graphql(
        graphqlOperation(mutations.updateListing, {
          input: { ...listingData, id: listingId },
        })
      );
      return response.data.updateListing;
    } catch (error) {
      console.error("Error updating listing:", error);
      throw error;
    }
  }

  // async deleteListing(listingAddress: string) {
  //     try {
  //         const response = await API.graphql(graphqlOperation(mutations.deleteListing, { input: { address: listingAddress }}));
  //         return response;
  //     } catch (error) {
  //         console.error('Error deleting listing:', error);
  //         throw error;
  //     }
  // }
  async deleteListing(input: { address: string }) {
    try {
      const listingDetails = {
        address: input.address,
      };
      const deletedListing = await API.graphql({
        query: mutations.deleteListing,
        variables: { input: { address: listingDetails.address } },
      });
      return deletedListing;
    } catch (error) {
      console.error("Error deleting listing:", error);
      throw error;
    }
  }
  // const graphqlData: any = await API.graphql(
  //   graphqlOperation(deleteListing, input)
  // );
  // if (graphqlData) {
  //   // setAlertType("success");
  //   return graphqlData;
  // } else {
  //   console.error("Error deleting listing:", graphqlData);
  //   return graphqlData?.errors;
  // }
}

export default ListingsUtils;
