// In src/registerServiceWorker.js
import { ServiceWorker } from '@aws-amplify/core';

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const sw = new ServiceWorker();
      sw.register('/service-worker.js')
        .then((registration) => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        })
        .catch((error) => {
          console.error('ServiceWorker registration failed: ', error);
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}