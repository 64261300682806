import React, { useEffect, useState } from "react";
import { Button, Card, Flex, Menu, MenuItem, Text } from "@aws-amplify/ui-react";
import AppMenu from "./AppMenu";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

type MainNavBarProps = {
  props: {
    activeTab: string;
    attributes?: any;
    // Add other properties as needed
  };
  activeTab: string; // Add the activeTab property to the type definition
  attributes?: any; // Add the attributes property to the type definition
};

const MainNavBar: React.FC<MainNavBarProps> = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(
    window.location.pathname.split("/")[1] ?? "home"
  );
  const propsRef = React.useRef(props);
  useEffect(() => {
    const handleResize = () => {
      let size = window.innerWidth;
      setIsMobile(size <= 768 ? true : false);
    };

    propsRef.current = {...props, activeTab: activeTab};

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // <View>
    <>
      {!isMobile ? (
        <Flex>
          <Card {...styles.navbar}>
            <Link to="/" onClick={() => setActiveTab("home")}>
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "home" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                Home
              </Button>
            </Link>
            <Link to="/about" onClick={() => setActiveTab("about")}>
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "about" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                About
              </Button>
            </Link>
            <Link to="/contact" onClick={() => setActiveTab("contact")}>
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "contact" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                Contact
              </Button>
            </Link>
            <Link to="/search" onClick={() => setActiveTab("search")}>
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "search" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                Search
              </Button>
            </Link>
            <Link
              to="/setcommission"
              onClick={() => setActiveTab("setcommission")}
            >
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "setcommission" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                Add Listing
              </Button>
            </Link>
            <Link to="/mylistings" onClick={() => setActiveTab("mylistings")}>
              <Button
                {...styles.tabLink}
                style={{
                  backgroundColor:
                    activeTab === "mylistings" ? "rgb(28 40 52)" : "inherit",
                }}
              >
                My Listings
              </Button>
            </Link>
            <Menu
              onClick={() => setActiveTab("profile")}
              style={{
                backgroundColor:
                  activeTab === "profile" ? "rgb(28 40 52)" : "inherit",
              }}
              // style={styles.initials}
              position={"absolute"}
              direction={"column"}
              backgroundColor="rgb(44, 62, 80) !important"
              trigger={
                <Link to="/profile" onClick={() => setActiveTab("profile")}>
                  <Button
                    onClick={() => setActiveTab("profile")}
                    {...styles.tabLink}
                    style={{
                      backgroundColor:
                        activeTab === "profile" ? "rgb(28 40 52)" : "inherit",
                    }}
                  >
                    Settings
                  </Button>
                </Link>
              }
              content="Settings"
              // display={"flex"}
              padding={"small"}
              about="Settings"
              // size="large"
            >
              <Text>{props?.attributes?.email}</Text>
              <Link to="/profile">
                <MenuItem width={"100%"}>Profile</MenuItem>
              </Link>
              {props.props?.attributes && (
              <><Link
                  to="/privacy"
                >
                  <MenuItem width={"100%"}>Privacy Policy</MenuItem>
                </Link><Link
                  to="/"
                  onClick={() => {
                    localStorage.clear();
                    Auth.signOut();
                    // window.location.reload();
                  } }
                >
                    <MenuItem width={"100%"}>Sign Out</MenuItem>
                  </Link></>
              )}
            </Menu>
          </Card>
        </Flex>
      ) : (
        <AppMenu {...props} />
      )}
    </>
    // </View>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    flexdirection: "column",
    alignitems: "center",
  },
  button: {
    cursor: "pointer",
    margin: 4,
    border: "1px solid #ddd",
    width: 225,
    ":hover": {
      backgroundcolor: "rgba(0, 0, 0, .1)",
    },
    ":active": {
      backgroundcolor: "rgba(0, 0, 0, .2)",
    },
  },
  input: {
    padding: 8,
    height: 40,
    width: 225,
    border: "1px solid #ddd",
  },
  miniButton: {
    padding: 5,
    margin: 0,
  },
  submitButton: {
    padding: "10px 20px",
    margin: "10px 0px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "hsl(210, 50%, 10%)",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease 0s",
  },
  footer: {
    backgroundColor: "hsl(210, 50%, 10%)",
    color: "white",
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "flex",
    textAlign: "start",
  },
  container: {
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    color: "#333",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#0d1a26",
    color: "white",
    // position: "sticky",
    width: "100%",
    top: 0,
    display: "flex",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
  headerImage: {
    height: "100px",
    verticalAlign: "middle",
  },
  headerTitle: {
    display: "inline",
    verticalAlign: "middle",
    fontSize: "30px",
    margin: 0,
    textAlign: "center",
    width: "100%",
  },
  navbar: {
    display: "flex",
    backgroundColor: "rgb(44, 62, 80)",
    padding: "10px 0",
    justifyContent: "center",
    width: "100%",
    top: "0",
    position: "sticky",
  },
  tabLink: {
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    // backgroundColor: "inherit",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    ":hover": {
      backgroundColor: "rgb(28 40 52)",
    },
    ":active": {
      backgroundColor: "#d35400",
    },
  },
  tabContent: (active: any) => ({
    display: active ? "block" : "none",
    padding: "20px",
    background: "white",
    color: "#0d1a26",
    fontSize: "1.2em",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    transition: "transform 0.3s, box-shadow 0.3s",
    marginBottom: "10%",
    alignContent: "space-evenly",
    h3: {
      color: "#333",
      fontSize: "2em",
      marginBottom: "10px",
      marginTop: 0,
    },
    p: {
      textAlign: "justify",
      lineHeight: 1.6,
      marginTop: "1em",
    },
  }),
  footerContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
};

export default MainNavBar;
