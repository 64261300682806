import {
  Alert,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Image,
  Label,
  Loader,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import React, { Component, useEffect } from "react";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { Listing } from "./models";
import { Link } from "react-router-dom";


type Agent = {
  brokerage: string;
  phone: string;
  email: string;
  name: string;
};

interface ListingDetailsProps {
  listing: Listing;
  bucketKey?: string;
  agent?: Agent;
  contract?: string;
  onClose: () => void;
}

const ListingDetails: React.FC<ListingDetailsProps> = ({
  listing,
  onClose,
}) => {
  const [listingAgent, setListingAgent] = React.useState<Agent | null>(null);
  const [contract, setContract] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchS3Object = async (key: string) => {
    try {
      const currentCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: "us-east-1", // Replace with your Cognito region
        credentials: Auth.essentialCredentials(currentCredentials),
      });
      const s3 = new AWS.S3();
      const params = {
        Bucket: "cbroker-contracts-bucket97c51-dev",
        Key: key,
      };

      const signedUrl = await new Promise<string>((resolve, reject) => {
        s3.getSignedUrl("getObject", params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            resolve(url);
          }
        });
      });

      let response = {} as Response;
      try {
        const tryResponse = await fetch(signedUrl);
        if (!tryResponse.ok) {
          setContract(null);
          return;
        } else {
          response = tryResponse;
        }
      } catch (error) {
        console.error("Error loading contracts..");
      }

      const blob = await response.blob();
      const dataUrl = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      setContract(dataUrl);
    } catch (error) {
      // console.error("Error fetching S3 object:", error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const currentCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: "us-east-1", // Replace with your Cognito region
        credentials: Auth.essentialCredentials(currentCredentials),
      });

      const cognitoId = listing.owner || "";
      const userPoolId = "us-east-1_jIS6jkXcS";

      const cognitoProvider = new AWS.CognitoIdentityServiceProvider();
      const params = {
        UserPoolId: userPoolId,
        Username: cognitoId,
      };

      const data = await new Promise<
        AWS.CognitoIdentityServiceProvider.AdminGetUserResponse
      >((resolve, reject) => {
        cognitoProvider.adminGetUser(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });

      const agentDetails = {
        brokerage:
          data.UserAttributes?.find((attr) => attr.Name === "custom:brokerage")
            ?.Value || "",
        phone:
          data.UserAttributes?.find((attr) => attr.Name === "phone_number")
            ?.Value || "",
        email:
          data.UserAttributes?.find((attr) => attr.Name === "email")?.Value ||
          "",
        name:
          data.UserAttributes?.find((attr) => attr.Name === "name")?.Value ||
          "",
        bucketKey: "",
      };

      const userInfo = {
        ...agentDetails,
        bucketKey: `public/${agentDetails.email}/${listing.address}`,
      };

      setListingAgent(agentDetails);

      return userInfo;
    } catch (error) {
      setLoading(false);
      // console.error("Error fetching user info:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const userInfoResponse = await fetchUserInfo();
      if (userInfoResponse) {
        await fetchS3Object(userInfoResponse.bucketKey).then(() => {
          setLoading(false);
        });
      }
    };

    fetchData();
  }, []);

  const formattedEmail = (email: string | undefined) => {
    return <a href={`mailto:${email}`}>{email}</a>;
  };

  const formattedPhone = (phone: string | undefined) => {
    let val = `tel:${phone ?? ""}`;
    return <a href={val}>{phone}</a>;
  };

  const { tokens } = useTheme();
  const toolTipCommission = "The commission amount the buyer agent will receive for selling the property. This is negotioable between all parties involved.";

  return (
    <View>
      {loading ? (
        <View>
          <Loader
            variation="linear"
            emptyColor={"hsl(210deg 29.03% 24.31%)"}
            filledColor={"white"}
          />
          {/* <Loader
            width={"100%"}
            top={"50%"}
            marginLeft={"auto"}
            marginRight={"auto"}
            display={"flex"}
            position={"fixed"}
            emptyColor={"hsl(210deg 29.03% 24.31%)"}
            filledColor={tokens.colors.orange[40]}
          /> */}
        </View>
      ) : (
        <Grid>
          {/* <Grid border={"1px solid grey"}> */}
          <Flex marginBottom={"10px"}>
            <Button onClick={onClose}>Back</Button>
          </Flex>
          <Heading style={styles.cardHeader} textAlign={"center"} level={4}>
            Listing Details
          </Heading>
          <Card>
            <Heading style={styles.cardHeader}>Address</Heading>
            <Text>{listing.address}</Text>

            {/* <Heading style={styles.cardHeader}>Property Listing</Heading>
            <a target="_blank" href={listing.mlsnumber ?? "#"}>{listing.mlsnumber}</a> */}
          </Card>
          <Card>
            <Heading style={styles.cardHeader}>Commission (negotiable)</Heading>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            </View>
            <Text>
              Buyer Agent: {listing.commissionType === "Flat Fee" ? "$" : ""}
              {listing.commission}
              {listing.commissionType === "Percentage" ? "%" : ""}
            </Text>
            {Number(listing.transCoordCommission) > 0 ? (
              <Text>
                Transaction Coordinator:{" "}
                {listing.transCoordCommissionType === "Flat Fee" ? "$" : ""}
                {listing.transCoordCommission}
                {listing.transCoordCommissionType === "Percentage" ? "%" : ""}
              </Text>
            ) : null}
          </Card>
          <Card>
            <Heading style={styles.cardHeader}>Listing Agent</Heading>
            <Text>{listing.agent}</Text>
            <Text>{listingAgent?.brokerage}</Text>
            {/* <Text>{listingAgent?.name}</Text> */}
            <Text>{formattedPhone(listingAgent?.phone)}</Text>
            <Text>{formattedEmail(listingAgent?.email)}</Text>
          </Card>
          {/* <Grid padding={"small"}> */}
          {contract ? (
            <Card style={{ justifyContent: "center" }}>
              <Heading style={styles.cardHeader}>Commission Agreement</Heading>
              <br />
              <Image width={"fit-content"} src={contract} alt="contract" />
            </Card>
          ) : (
            <Card>
              <Heading style={styles.cardHeader}>Commission Agreement</Heading>
              <Alert variation="warning">This listing has no documents.</Alert>
            </Card>
          )}
          {/* </Grid> */}
          <Card>
            <strong
            // fontWeight={"extrabold"}
            >
              Initial Posting
            </strong>
            <Text>{new Date(listing.createdAt ?? "").toLocaleString()}</Text>

            <br />
            {/* </Card>
        <Card> */}
            <strong>Last Updated</strong>
            <Text>{new Date(listing.updatedAt ?? "").toLocaleString()}</Text>
          </Card>
          <Button margin={"1em 0"} width={"100%"} onClick={onClose}>
            Close
          </Button>
        </Grid>
      )}
    </View>
  );
};

const styles = {
  cardHeader: {
    level: "4",
    // fontSize: "medium",
    fontWeight: "extrabold",
  }
};

export default ListingDetails;
