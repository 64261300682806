import React, { ReactNode } from 'react';

interface AlertModuleProps {
    children: ReactNode;
    okButtonText?: string;
    isVisible: boolean;
}

const AlertModule: React.FC<AlertModuleProps> = ({ children, okButtonText, isVisible }) => {
    return (
        <div className="modal-container">
            <div className="modal-content">{children}</div>
            {okButtonText && <button>{okButtonText}</button>}
        </div>
    );
};

export default AlertModule;