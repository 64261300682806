import React from "react";
import ContactUsComponent from "./ui-components/ContactUs";
import { Card, Flex, View, Heading, Text } from "@aws-amplify/ui-react";
import { createCandidate } from "./graphql/mutations";
import { API } from "aws-amplify";
import CandidateCreateForm from "./ui-components/CandidateCreateForm";
import { Helmet } from "react-helmet";

const ContactUs: React.FC = () => {
  const [messageSent, setMessageSent] = React.useState(false);
  const helmet = (
    <Helmet>
      <title>Contact Us</title>
      <meta
        name="google-adsense-account"
        content="ca-pub-9591636847579215"
      ></meta>
      <meta name="description" content="Get in touch with Cooperating Broker." />
      <meta name="author" content="Cooperating Broker" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Contact Us" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Get in touch with Cooperating Broker." />
      <meta
        property="og:url"
        content="https://www.cooperating-broker.com/contact"
      />
      <meta
        name="keywords"
        content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
        transactions, law, regulation, seller commission, buyer commission"
      />
    </Helmet>
  );
  return (
    <View>
      <meta name="description" content="Contact Us page for 'Cooperating Broker' app." />
      <meta name="author" content="Cooperating Broker" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Contact" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Contact Us page for 'Cooperating Broker' app." />
      <meta property="og:url" content="https://www.cooperating-broker.com/contact" />
      <meta
        name="keywords"
        content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission, contact us"
      />
      {helmet}
      <View>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'GT-KVFV2LSC');
            `}
        </script>
      </View>
      <Flex direction={"column"}>
        {messageSent ? (
          <Card id="successfulMessage">
            <Heading level={4} textAlign={"center"} marginBottom={"10px"}>
              Contact Us
            </Heading>
            <Text>
              Thank you for your interest in Cooperating Broker!
            </Text>
            <Text>
              Someone from our team will be in touch with you shortly.
            </Text>
          </Card>
        ) : (
          <Card id="contactUsForm" style={styles.container}>
            <Heading level={4} textAlign={"center"} marginBottom={"10px"}>
              Contact Us
            </Heading>
            <Text textAlign={"center"}>
              If you have any questions or comments, please let us know by
              filling the form below.
            </Text>
            <Text textAlign={"center"}>We'd love to hear from you!</Text>
            <CandidateCreateForm
              onSuccess={() => {
                setMessageSent(true);
                // console.log("Candidate created successfully");
                window.scrollTo(0, 0);
              }}
              onError={(error) => {
                alert(JSON.stringify(error));
              }}
            />
          </Card>
        )}
      </Flex>
    </View>
  );
};

const styles = {
  container: {
    marginBottom: "10%",
    // display: "table",
    // height: "100%",
    // width: "100%",
  },
};

export default ContactUs;
