import React, { useState } from "react";
import { Text, Heading, View, Card } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <head>
        <script async src="https://www.googletagmanager.com/gtag/js?id=GT-KVFV2LSC"></script>
      </head>
      <View>
        <Helmet>
          <title>Cooperating Broker</title>
          <meta charSet="utf-8" title="Cooperating Broker" />
          <meta name="google-adsense-account" content="ca-pub-9591636847579215"></meta>
          <meta name="description" content="Cooperating Broker streamlines commission agreements for real estate agents. Save time and ensure transparency." />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="author" content="Cooperating Broker" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Home" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Home Page of 'Cooperating Broker' app." />
          <meta property="og:url" content="https://www.cooperating-broker.com/" />
          <meta
            name="keywords"
            content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission"
          />
        </Helmet>

        <Card style={styles.tabContent}>

          <Heading level={3}
            textAlign={"center"}
          >
            Welcome!
          </Heading>
          <Card>
            <Text>
              <strong>Cooperating Broker</strong> is the solution to the new Real
              Estate regulations regarding the display of commissions on MLS-related sites.
              <br />
              👋 <strong>Say goodbye</strong> to endless phone calls and
              uncertainty about whether or not a seller is willing to pay a buyers
              agent.
            </Text>
          </Card>
          <Card>
            <Text>
              Are you a <strong>listing agent</strong> selling a home for a client? Cooperating Broker allows you to advertise the commission you are offering to a buyer's agent.
              Give yourself valuable time back so you can focus on the needs of your client, not repetitive conversations.
            </Text>
          </Card>
          <Card>
            <Text>
              Are you a <strong>home owner</strong> listing your home "For Sale By Owner"? If you don't have an agent to support you,
              be prepared to answer a lot of phone calls from buyer agents asking about your commission! Use Cooperating Broker to
              share it and avoid any extra hassel!
            </Text>
          </Card>
        </Card>
      </View>
    </>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    flexdirection: "column",
    alignitems: "center",
  },
  button: {
    cursor: "pointer",
    margin: 4,
    border: "1px solid #ddd",
    width: 225,
    ":hover": {
      backgroundcolor: "rgba(0, 0, 0, .1)",
    },
  },
  input: {
    padding: 8,
    height: 40,
    width: 225,
    border: "1px solid #ddd",
  },
  miniButton: {
    padding: 5,
    margin: 0,
  },
  submitButton: {
    padding: "10px 20px",
    margin: "10px 0px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "hsl(210, 50%, 10%)",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease 0s",
  },
  footer: {
    backgroundColor: "hsl(210, 50%, 10%)",
    color: "white",
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "flex",
    textAlign: "start",
  },
  container: {
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    color: "#333",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#0d1a26",
    color: "white",
    // position: "sticky",
    width: "100%",
    top: 0,
    display: "flex",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
  headerImage: {
    height: "100px",
    verticalAlign: "middle",
  },
  headerTitle: {
    display: "inline",
    verticalAlign: "middle",
    fontSize: "30px",
    margin: 0,
    textAlign: "center",
    width: "100%",
  },
  navbar: {
    display: "flex",
    backgroundColor: "rgb(44, 62, 80)",
    padding: "10px 0",
    justifyContent: "center",
    width: "100%",
    top: "0",
    position: "sticky",
  },
  tabLink: {
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "inherit",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    ":hover": {
      backgroundColor: "#e67e22",
    },
  },
  tabContent: {
    display: "block",
    padding: "20px",
    background: "white",
    color: "#0d1a26",
    fontSize: "1.2em",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s, box-shadow 0.3s",
    marginBottom: "10%",
    h3: {
      color: "#333",
      fontSize: "2em",
      marginBottom: "10px",
      marginTop: 0,
      textAlign: "center",
    },
    p: {
      textAlign: "justify",
      lineHeight: 1.6,
      marginTop: "1em",
    },
  },
  footerContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
};
export default Home;
