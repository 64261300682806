import {
  View,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  Flex,
} from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import React from "react";
import { Link, Redirect } from "react-router-dom";

interface AppMenuProps {
  props: any;
  // attributes?: any;
}

const AppMenu: React.FC<AppMenuProps> = (props) => {
  // console.log(props);
  return (
    <View>
      <Flex
        style={{
          justifyContent: "space-around",
          // backgroundColor: "rgba(0, 0, 0, 0.5) 1px 1px 5px",
        }}
      >
        <Menu
          // style={styles.initials}
          position={"absolute"}
          backgroundColor="rgb(44, 62, 80) !important"
          // alignItems={"center"}
          trigger={
            <View
            backgroundColor={"rgb(44, 62, 80)"}
              // color="transparent"
              padding={"large"}
              display={"flex"}
              borderRadius={"10px"}
              style={{cursor: "pointer"}}
              border={"none"}
              boxShadow={"rgba(0, 0, 0, 0.5) 1px 1px 5px"}
              alignSelf={"center"}
              // width={"100%"}
              // justifyContent={"left"}
              // backgroundColor={"transparent"}
            >
              <svg
                color="white"
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
                  fill="currentColor"
                ></path>
              </svg>
              <Text color={"whitesmoke"} paddingLeft={"1em"}>
                Menu
              </Text>
            </View>
          }
          content="Menu"
          // display={"flex"}
          // padding={"small"}
          about="Menu"
          size="large"
        >
          <Link to="/about">
            <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>ℹ️</Text> About Us</MenuItem>
          </Link>
          <Link to="/contact">
            <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>✉️</Text> Get In Touch</MenuItem>
          </Link>
          <Link to="/search">
            <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>🔍</Text>Search</MenuItem>
          </Link>
          <Link to="/mylistings">
            <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>📋</Text>My Listings</MenuItem>
          </Link>
          <Link to="/setcommission">
            <MenuItem width={"100%"}><Text style={{fontSize: "2em", color: "white", marginRight: "1em"}}>＋</Text>Add Listing</MenuItem>
          </Link>
          {/* <Link to="/mycontracts">
                <MenuItem width={"100%"}>Commission Agreements</MenuItem>
              </Link> */}
          {/* <Link to="/profile">
            <MenuItem width={"100%"}>Profile</MenuItem>
          </Link> */}
          {/* <Link to="/pricing">
          <MenuItem width={"100%"}>FAQs</MenuItem>
        </Link> */}
        {/* {props.props?.attributes ? (
          <Link
            to="/"
            onClick={() => {
              localStorage.clear();
              Auth.signOut();
              Hub.dispatch("auth", { event: "signOut" });
              window.location.href = "/";
            }}
          >
            <MenuItem width={"100%"}>
              {props.props?.attributes ? "Sign Out" : "Sign In"}
            </MenuItem>
          </Link>
        ) : (
          <Link to="/profile">
            <MenuItem width={"100%"}>Sign In</MenuItem>
          </Link>
        )} */}
        </Menu>
        {props.props?.attributes && (
          <Menu
            // position={"absolute"}
            trigger={
              <View style={styles.initials}>
                {/* <Text column={2} style={{ color: "white" }}> */}
                <strong>@{props.props.attributes?.email.split("@")[0]}</strong>
                {/* </Text> */}
              </View>
            }
          >
            <Link to="/profile">
              <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>👤</Text>Profile</MenuItem>
            </Link>
            <Link to="/privacy">
              <MenuItem width={"100%"}><Text style={{fontSize: "2em", marginRight: "1em"}}>🔒</Text>Privacy Policy</MenuItem>
            </Link>
            <Link to="/">
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  Auth.signOut();
                  // Auth.signOut({
                  // global: true,
                  // });
                }}
                width={"100%"}
              >
                <Text style={{fontSize: "2em", marginRight: "1em"}}>
                  🚪
                </Text>
                Sign Out
              </MenuItem>
            </Link>
          </Menu>
        )}
      </Flex>
    </View>
  );
};

const styles = {
  amplifyLogo: {
    height: 30,
    marginLeft: 25,
  },
  initials: {
    cursor: "pointer",
    background: "#2c3e50",
    color: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.5) 1px 1px 5px",
    // background: "lightgray",
    // color: "black !important",
    // padding: "10px",
    // borderRadius: "50%",
    // fontWeight: "bolder",
  },
  title: {
    fontWeight: 300,
    color: "white",
    margin: 0,
    textalign: "left",
    marginleft: 10,
  },
  navContainer: {
    display: "flex",
    flex: 1,
    paddingleft: 50,
    margintop: 6,
  },
  link: {
    textDecoration: "none",
  },
  navItem: {
    marginleft: 20,
    color: "white",
    paddingbottom: "4px",
    borderbottom: "2px solid transparent",
    ":hover": {
      borderbottom: "2px solid white",
    },
  },
  // container: {
  //   height: "80px",
  //   alignitems: "center",
  //   width: "100%",
  //   backgroundcolor: "green",
  //   display: "flex",
  // },
};

export default AppMenu;
