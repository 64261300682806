import {
  Flex,
  Heading,
  View,
  Card,
  Text,
  ThemeProvider,
  defaultDarkModeOverride,
  Divider,
} from "@aws-amplify/ui-react";
import React from "react";


function ProductInfo() {
  
  const theme = {
    name: "my-theme",
    overrides: [defaultDarkModeOverride],
  };

  return (
    // <ThemeProvider colorMode="system" theme={theme}>
      <View
      //   backgroundColor={"grey"}
      >
        <Card height={"100vh"}>
          <Heading textAlign={"center"} level={3}>
            Pricing
          </Heading>
          <Flex
            direction={"column"}
            width={"100%"}
            //  className="product-info"
          >
            <Card>
              {/* <Heading level={4}>Pricing</Heading> */}
              <View className="product free-trial">
                <Heading level={5}>Free Trial</Heading>
                <Text>
                  Give Cooperating Broker a try for free! Sign up for a
                  no-commitment free trial.
                </Text>
              </View>
            </Card>
            {/* <Card>
              <View className="product monthly-license">
                <Heading level={5}>Monthly License</Heading>
                <Text>
                  The pay-per-month plan may be right for you. Maybe you're
                  testing the waters as an agent, or broker shopping. Subscribe
                  on a month-to-month basis with the freedom to cancel anytime.
                </Text>
              </View>
            </Card>
            <Card>
              <View className="product yearly-license">
                <Heading level={5}>Yearly License</Heading>
                <Text>
                  Save 20% by paying by the year! Choose our yearly license for
                  continuous access.
                </Text>
              </View>
            </Card> */}
          </Flex>
        </Card>
      </View>
    // </ThemeProvider>
  );
}

export default ProductInfo;
