// Function to check camera permission status
export function checkCameraPermission() {
    navigator.permissions.query({ name: 'camera' }).then(function(permissionStatus) {
        console.log('Camera permission status:', permissionStatus.state);

        if (permissionStatus.state === 'granted') {
            // alert('Permission granted');
            console.log('Camera access already granted.');
            // You can access the camera here
        } else if (permissionStatus.state === 'prompt') {
            // alert('Permission needs to be requested');
            console.log('Camera access needs to be requested.');
            // Request camera access
            // requestCameraAccess();
        } else {
            // alert('Permission denied');
            console.log('Camera access denied.');
        }

        permissionStatus.onchange = function() {
            console.log('Camera permission status changed to:', this.state);
        };
        
        return permissionStatus.state;
    }).catch(function(error) {
        console.error('Error checking camera permissions:', error);
    });
}

// Function to request camera access
export function requestCameraAccess() {
    navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
        console.log('Camera access granted.');
        // You can use the stream here, e.g., display it in a video element
        const video = document.querySelector('video');
        video.srcObject = stream;
    }).catch(function(error) {
        console.error('Error requesting camera access:', error);
    });
}
