import React, { useState } from "react";
import ListingUpdateForm, {
  ListingUpdateFormInputValues,
} from "./ui-components/ListingUpdateForm";
import { Listing } from "./models";
import {
  Alert,
  Card,
  Heading,
  Loader,
  ThemeProvider,
  View,
} from "@aws-amplify/ui-react";
import { Auth, Storage } from "aws-amplify";
import { Redirect, useHistory } from "react-router-dom";
import ManageAgreements from "./ManageAgreements";

interface EditCommissionProps {
  listingId: string;
  listing?: Listing;
  props?: any;
}

async function handleUpload(contract: File, selectedAddress: string) {
  const currentUser = await Auth.currentAuthenticatedUser();
  const userAttributes = await Auth.userAttributes(currentUser);
  const email = userAttributes.filter((m) => m.Name === "email")[0].Value;
  const result = await Storage.put(
    `${email}/${selectedAddress}`,
    contract as File,
    {
      contentType: contract.type ?? "application/pdf",
      level: "public",
    }
  ).catch((error) => {
    console.error("Error uploading file:", error);
  });
  // console.log("S3 Key: ", result?.key);
}

const EditCommission: React.FC<EditCommissionProps> = ({ listing, props }) => {
  const [selectedAddress, setSelectedAddress] = useState<string>("" as string);
  const [contract, setContract] = useState<File | null>(null);
  const [alert, setAlert] = useState({ type: "", message: "" } as any);
  const [isLoading, setIsLoading] = useState(false);
  const [focusPoint, setFocusPoint] = useState(0);
  const handleFileUpload = (file: File) => {
    setContract(file);
  };

  const initialValues = {
    broker: listing?.broker ?? "",
    agent: listing?.agent ?? "",
    address: listing?.address ?? "",
    mlsnumber: listing?.mlsnumber ?? "",
    commissionType: listing?.commissionType ?? "",
    commission: listing?.commission ?? "",
    transCoordCommissionType: listing?.transCoordCommissionType ?? "",
    transCoordCommission: listing?.transCoordCommission ?? "",
    isActive: listing?.isActive ?? true,
  };

  const theme = {
    name: "custom-theme",
    tokens: {
      components: {
        card: {
          backgroundColor: { value: "{colors.background.secondary}" },
          outlined: {
            borderColor: { value: "{colors.black}" },
          },
        },
        heading: {
          // color: { value: '{colors.secondary[80]}' },
          color: { value: "{colors.primary[80]}" },
          backgroundColor: { value: "{colors.green}" },
        },
        text: {
          color: { value: "{colors.primary[80]}" },
        },
      },
    },
  };

  return isLoading ? (
    <Loader
      variation="linear"
      emptyColor={"hsl(210deg 29.03% 24.31%)"}
      filledColor={"white"}
    />
  ) : (
    <View backgroundColor={"rgb(44, 62, 80)"}>
        {/* if alert has a value, show the <Alert> component */}
        {/* {window.scrollTo(0, window.screenY)} */}
        {alert.message && (
          <Alert

            variation={alert.type}
            // dismissible
            onDismiss={() => setAlert({ type: "", message: "" })}
          >
            {alert.message}
          </Alert>
        )}
      <Card className="formContainer" style={styles.container}>
        <Card>
          <ListingUpdateForm
            // {...initialValues}
            // {...listing}
            listing={listing}
            address={listing?.address}
            onSubmit={(fields: ListingUpdateFormInputValues) => {
              try {
                if (contract) {
                  handleUpload(contract, listing?.address ?? "")
                    .then((response) => {
                      setAlert({
                        type: "success",
                        message: "File uploaded successfully",
                      });
                      // window.scrollTo(0, focusPoint);
                      // console.log(
                      //   `File uploaded successfully ${JSON.stringify(
                      //     response
                      //   )}:`,
                      //   response
                      // );
                    })
                    .catch((error) => {
                      console.error("Error uploading file:", error);
                    });
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              }

              const updatedFields: ListingUpdateFormInputValues = {};
              updatedFields.broker = fields.broker;
              updatedFields.agent = fields.agent;
              updatedFields.address = fields.address?.trim();
              updatedFields.mlsnumber = fields.mlsnumber?.trim();
              updatedFields.commissionType = fields.commissionType?.trim();
              updatedFields.commission = fields.commission;
              updatedFields.transCoordCommissionType =
                fields.transCoordCommissionType;
              updatedFields.transCoordCommission = fields.transCoordCommission;
              updatedFields.isActive = fields.isActive;
              // updatedFields.contract = "contract";

              return updatedFields;
            }}
            onSuccess={(fields) => {
              // setFocusPoint(window.screenY);
              window.scrollTo(0, 0);
              setAlert({
                type: "success",
                message: "Listing updated successfully",
              });
              // window.location.reload();
              // return <Redirect to="/search" />;
              // window.location.reload();
            }}
            overrides={{
              // ListingUpdateFormGrid: {
              //   display: "flex",
              //   flexDirection: "column",
              //   gap: "10px",
              //   padding: "10px",
              // },
              RightAlignCTASubFlex: {
                justifyContent: "right",
              },
              address: {
                display: "none",
              },
              initialValues: initialValues,
              // {
              //   broker: listing?.broker ?? "",
              //   agent: listing?.agent ?? "",
              //   address: listing?.address ?? "",
              //   mlsnumber: listing?.mlsnumber ?? "",
              //   commissionType: listing?.commissionType ?? "",
              //   commission: listing?.commission ?? "",
              //   transCoordCommissionType:
              //     listing?.transCoordCommissionType ?? "",
              //   transCoordCommission: listing?.transCoordCommission ?? "",
              //   isActive: listing?.isActive ?? true,
              //   // contract: null,
              // },
              // broker: {
              //   display: "none",
              // },
              // agent: {
              //   display: "none",
              // },
              ResetButton: { label: "Cancel", display: "none" },
              // SubmitButton: {
              //   label: "Update",
              //   justifyContent: "right",
              //   align: "right",
              // },
              commissionType: {
                // options: ["Flat Fee", "Percentage"],
                // value: listing?.commissionType ?? "",
              },
              ListingUpdateFormGrid: {
                children: (
                  <Heading>{listing?.address}</Heading>
                ),
                display: "flex",
                // flexDirection: "column",
                gap: "10px",
                padding: "10px",
              },
              // ...props
            }}
          />
        </Card>
        {/* <Heading textAlign={"center"} level={5} marginTop={"2em"}>
          Commission Agreement
        </Heading> */}
        <ManageAgreements
          onFileUpload={handleFileUpload}
          user={props.attributes}
          history={undefined}
          location={undefined}
          // staticContext={undefined}
          listing={listing}
          selectedAddress={listing}
          // isLoading={isLoading}
          setIsLoading={(val) => setIsLoading(val)}
          // props={props}
        />
      </Card>
    </View>
  );
};

const styles = {
  boxShadowed: {
    boxShadow: "rgba(0, 0, 0, 0.5) 5px 6px 5px",
    padding: "10px",
  },
  amplifyLabel: {
    marginTop: "10px",
  },
  container: {
    marginBottom: "10%",
    // display: "table",
    // height: "100%",
    // width: "100%",
    label: {
      padding: "10px",
    },
  },
};

export default EditCommission;
