import React, { Component, StrictMode } from "react";
import { Auth, Hub } from "aws-amplify";

import "./App.css";
import Router from "./Router";
import UserContext from "./UserContext";
import { Redirect } from "react-router-dom";

class App extends Component {
  state = {
    currentUser: {},
    isLoaded: false,
  };
  componentDidMount() {
    this.updateCurrentUser();
    // Hub.listen("auth", this);
    Hub.listen("auth", (data) => {
      const { payload } = data;
      switch (payload.event) {
        case "signIn":
          this.updateCurrentUser();
          window.location.href = "/search";
          break;
        case "signOut":
          this.updateCurrentUser();
          window.location.href = "/";
          break;
        default:
          break;
      }
    });
  }
  // onHubCapsule(capsule) {
  //   const { channel, payload } = capsule;
  //   if (channel === "auth" && payload.event !== "signIn") {
  //     this.updateCurrentUser();
  //     window.location.href = "/search";
  //     // return <Redirect to="/search" />;
  //   }
  // }
  updateCurrentUser = async (user) => {
    if (user) {
      this.setState({ currentUser: user });
      // return <Redirect to="/search" />;
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ currentUser: user, isLoaded: true });
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true });
    }
  };
  render() {
    return (
      // <StrictMode>
        <UserContext.Provider
          value={{
            user: this.state.currentUser,
            updateCurrentUser: this.updateCurrentUser,
            isLoaded: this.state.isLoaded,
          }}
        >
          <div className="App">
            <Router props={this.state.currentUser} />
          </div>
        </UserContext.Provider>
      // </StrictMode>
    );
  }
}

export default App;
