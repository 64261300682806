import React, { useState } from "react";
import { ListingCreateForm } from "./ui-components";
import { Alert, Card, Flex, Heading, View } from "@aws-amplify/ui-react";
// import { ManageAgreements } from "./ManageAgreements";
import { Redirect, useHistory } from "react-router-dom";
import { Auth, Storage } from "aws-amplify"; // Import Storage from aws-amplify
import {
  ListingCreateFormInputValues,
  // ValidationResponse,
} from "./ui-components/ListingCreateForm";
import ManageAgreements from "./ManageAgreements";
import { Helmet } from "react-helmet";

async function handleUpload(contract: File, selectedAddress: string) {
  const currentUser = await Auth.currentAuthenticatedUser();
  const userAttributes = await Auth.userAttributes(currentUser);
  const email = userAttributes.filter((m) => m.Name === "email")[0].Value;
  const result = await Storage.put(
    `${email}/${selectedAddress}`,
    contract as File,
    {
      contentType: contract.type ?? "application/pdf",
      level: "public",
    }
  ).catch((error) => {
    console.error("Error uploading file:", error);
  });
  // console.log("S3 Key: ", result?.key);
}

const SetCommission: React.FC = (userParam: { user?: any } = {}) => {
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState<string>("" as string);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contract, setContract] = useState<File | null>(null);
  const handleFileUpload = (file: File) => {
    setContract(file);
  };
  const handleAddressChange = (address: string) => {
    setSelectedAddress(address);
  };
  const [alert, setAlert] = useState(null as any);

  const getUserMedia = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      console.log("User media available");
    } catch (error) {
      console.error("User media not available", error);
    }
  };

  return alert ? (
    <Alert
      isDismissible={true}
      variation={alert.type}
      // dismissible
      onDismiss={() => setAlert({ type: "", message: "" })}
    >
      {alert.message}
    </Alert>
  ) : (
    <View>
      <Helmet>
        <title>Set Commission</title>
        <meta
          name="google-adsense-account"
          content="ca-pub-9591636847579215"
        ></meta>
        <meta name="description" content="Add a listing to the marketplace." />
        <meta name="author" content="Cooperating Broker" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Add Listing" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.cooperating-broker.com/addlisting" />
        <meta
          property="og:description"
          content="Add a listing to the marketplace."
        />
        <meta
          property="og:url"
          content="https://www.cooperating-broker.com/addlisting"
        />
        <meta
          name="keywords"
          content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission, set your buyer commission"
        />
      </Helmet>
      <Card>
        <Heading textAlign={"center"} level={4}>
          Add Listing
        </Heading>
        <ManageAgreements
          onFileUpload={handleFileUpload}
          user={userParam.user}
          history={history}
          location={history.location}
          listing={selectedAddress}
          setIsLoading={setIsLoading}
          // match={undefined}
          // staticContext={undefined}
        />
        {/* <Flex> */}
        <Card>
          <ListingCreateForm
            {...userParam}
            // display="flow-root"
            // marginBottom={"10px"}
            // padding={"20px"}
            onSubmit={(fields: ListingCreateFormInputValues) => {
              try {
                if (contract) {
                  handleUpload(contract, selectedAddress)
                    .then((response) => {
                      // console.log(
                      //   `File uploaded successfully ${JSON.stringify(response)}:`,
                      //   response
                      // );
                    })
                    .catch((error) => {
                      // console.error("Error uploading file:", error);
                    });
                }
              } catch (error) {
                // console.error("Error uploading file:", error);
              }

              const updatedFields: ListingCreateFormInputValues = {};
              updatedFields.broker = fields.broker; //.filter((m: { Name: string; }) => m.Name === "custom:brokerage")[0]?.Value ?? "";
              updatedFields.agent = fields.agent;
              updatedFields.address = fields.address?.trim();
              updatedFields.mlsnumber = fields.mlsnumber?.trim();
              updatedFields.commissionType = fields.commissionType?.trim();
              updatedFields.commission = fields.commission;
              updatedFields.transCoordCommission = fields.transCoordCommission;
              updatedFields.transCoordCommissionType =
                fields.transCoordCommissionType;
              updatedFields.isActive = fields.isActive;
              return updatedFields;
            }}
            clearOnSuccess={false}
            // onValidate={{
            // broker: (value: string): ValidationResponse => {
            //   if (!value) {
            //     return { hasError: true, errorMessage: "Broker is required" };
            //   }
            //   return { hasError: false };
            // },
            // agent: (value: string): ValidationResponse => {
            //   if (!value) {
            //     return { hasError: false, errorMessage: "Agent is required" };
            //   }
            //   return { hasError: false };
            // },
            // }}
            overrides={{
              initialValues: {
                broker: userParam.user.attributes["custom:brokerage"],
                agent: userParam.user.attributes["custom:mlsNumber"],
                address: "",
                mlsnumber: "",
                commissionType:
                  userParam.user.attributes["custom:commissionType"] === "$"
                    ? "Flat Fee"
                    : "Percentage",
                commission: userParam.user.attributes["custom:commission"],
                transCoordCommissionType: "Flat Fee",
                transCoordCommission: 0,
                isActive: true,
              },
              address: {
                hasError: true,
                errorMessage: "Address is required",
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedAddress(e.target.value);
                },
                onError: (error: React.ChangeEvent<HTMLInputElement>) => {
                  alert(error.target.value);
                  return error.target.value;
                },
              },
              broker: {
                onError: (error: React.ChangeEvent<HTMLInputElement>) => {
                  alert(error.target.value);
                  return error.target.value;
                },
                // display: "none",
              },
              agent: {
                // display: "none",
              },
            }}
            onSuccess={() => {
              history.push("/mylistings");
              return <Redirect to="/mylistings" />;
            }}
            onError={(error: any) => {
              alert(error);
              // alert(error.message);
              // showError(error);
            }}
            onChange={(fields: ListingCreateFormInputValues) => {
              // console.log("Fields changed: ", fields);
              if (fields.address) {
                handleAddressChange(fields.address);
              }
              return fields;
            }}
          />
        </Card>
        {/* </Flex> */}
      </Card>
    </View>
  );
};

const styles = {
  boxShadowed: {
    boxShadow: "rgba(0, 0, 0, 0.5) 5px 6px 5px",
    padding: "10px",
  },
  amplifyLabel: {
    marginTop: "10px",
  },
  container: {
    marginBottom: "10%",
    // display: "table",
    // height: "100%",
    // width: "100%",
    label: {
      padding: "10px",
    },
  },
};
export default SetCommission;
