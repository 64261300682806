// reportWebVitals.js
import { logEvent } from './analytics';
// import { getCLS, getFID, getFCP, getLCP, getTTFB } from 'web-vitals';
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB  } from 'web-vitals'

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onFCP(onPerfEntry);
    onFID(onPerfEntry);
    onINP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};

const sendToAnalytics = ({ id, name, value }) => {
  logEvent('Web Vitals', `${name} - ${id}`, value);
};

export default function initVitals() {
  reportWebVitals(sendToAnalytics);
}
