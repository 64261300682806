import React from "react";
import { Link } from "react-router-dom";
import UserContext from "./UserContext";
import {
  Flex,
  defaultDarkModeOverride,
  Card,
  View,
  Image,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import logo from "./assets/cooperatingbroker_logo_clear.png";

class Header extends React.Component {
  static contextType = UserContext;
  state = {
    user: [],
    email: "",
    initials: "",
    broker: "",
    commission: "",
    commissionType: "",
    agent: "",
    brokerage: "",
    isActive: false,
  };
  theme = {
    name: "my-theme",
    overrides: [defaultDarkModeOverride],
  };

  componentDidMount(): void {
    try {
      const currentUser = async () => {
        return await Auth.currentAuthenticatedUser()
          .then((user) => Auth.userAttributes(user))
          .then((attributes): CognitoUserAttribute[] => {
            let email = attributes.filter((m) => m.Name === "email")[0].Value;
            let firstName = attributes
              .filter((m) => m.Name === "name")[0]
              .Value?.split(" ")[0];
            let lastName = attributes
              .filter((m) => m.Name === "name")[0]
              .Value?.split(" ")[1];
            // let firstName = attributes.filter((m) => m.Name === "given_name")[0]
            //   .Value;
            // let lastName = attributes.filter((m) => m.Name === "family_name")[0]
            //   .Value;
            let initials = firstName?.charAt(0) + lastName?.charAt(0);
            this.setState({
              user: attributes,
              email: email,
              initials: initials,
            });
            return attributes;
          })
          .catch((err) => {
            // console.log("Auth: ", err);
          });
      };
      currentUser();
    } catch (error) {
      // console.log("Error: ", error);
    }
  }
  render() {
    return (
      // <Card
      //   height={"100%"}
      //   minHeight={"fit-content"}
      //   color={{
      //     base: "white",
      //   }}
      // >
        <Flex
          // gap="40px"
          width="unset"
          height="unset"
          justifyContent="space-around"
          alignItems="center"
          backgroundColor={"white"}
          // position="relative"
          // boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
        >
          {/* <View style={{float: "left", width: "100%"}}> */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <View column={2}>
              <Image width={"8em"} alt="logo" src={logo}></Image>
              {/* <Heading column={1} level={4}>
                <Text textAlign={"center"}>Cooperating Broker</Text> */}
              {/* <Text textAlign={"center"}> Broker</Text> */}
              {/* </Heading> */}
            </View>
          </Link>
        </Flex>
        // {/* </Flex> */}
      // </Card>
    );
  }
}

const styles = {
  amplifyLogo: {
    height: 30,
    marginLeft: 25,
  },
  initials: {
    cursor: "pointer",
    background: "#2c3e50",
    color: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.5) 5px 5px 5px",
    // background: "lightgray",
    // color: "black !important",
    // padding: "10px",
    // borderRadius: "50%",
    // fontWeight: "bolder",
  },
  title: {
    fontWeight: 300,
    color: "white",
    margin: 0,
    textalign: "left",
    marginleft: 10,
  },
  navContainer: {
    display: "flex",
    flex: 1,
    paddingleft: 50,
    margintop: 6,
  },
  link: {
    textDecoration: "none",
  },
  navItem: {
    marginleft: 20,
    color: "white",
    paddingbottom: "4px",
    borderbottom: "2px solid transparent",
    ":hover": {
      borderbottom: "2px solid white",
    },
  },
  // container: {
  //   height: "80px",
  //   alignitems: "center",
  //   width: "100%",
  //   backgroundcolor: "green",
  //   display: "flex",
  // },
};

export default Header;
