import React from "react";
import { Card, Heading, View, Text } from "@aws-amplify/ui-react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    // <View padding="20px" maxWidth="800px" margin="auto">
    <View>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy for 'Cooperating Broker' app."
        />
        <meta name="author" content="Cooperating Broker" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Contact Us page for 'Cooperating Broker' app."
        />
        <meta
          property="og:url"
          content="https://www.cooperating-broker.com/privacy"
        />
        <meta
          name="keywords"
          content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
         transactions, law, regulation, seller commission, buyer commission, contact us"
        />
      </Helmet>
      <Card style={{ fontSize: "smaller", wordSpacing: "smaller" }}>
        <Card>
          <Heading level={5} textAlign={"center"}>
            Privacy Policy
          </Heading>
          <Text textAlign={"center"}>
            <strong>Effective Date:</strong> July 3, 2024
          </Text>
          {/* <Card> */}
          <Heading level={5}>1. Introduction</Heading>
          <Text style={styles.tabContent}>
            Welcome to Cooperating Broker ("we", "our", "us"). We are committed
            to protecting and respecting your privacy. This Privacy Policy
            explains how we collect, use, disclose, and safeguard your
            information when you use our app and services.
          </Text>
          {/* </Card> */}

          {/* <Card> */}
          <Heading level={5}>2. Information We Collect</Heading>
          <View>
            <Heading paddingLeft={"1em"} level={6}>
              a. Personal Data
            </Heading>
            <Text style={styles.tabContent}>
              We may collect personally identifiable information, including but
              not limited to:
              <ul>
                <li>
                  <Text>Name</Text>
                </li>
                <li>
                  <Text>Email address</Text>
                </li>
                <li>
                  <Text>Phone Number</Text>
                </li>
                <li>
                  <Text>
                    Professional information (e.g., real estate license number)
                  </Text>
                </li>
              </ul>
            </Text>
          </View>
          {/* </Card> */}

          {/* <Card> */}
          <Heading paddingLeft={"1em"} level={6}>
            b. Usage Data
          </Heading>
          <Text style={styles.tabContent}>
            We may collect information that your browser sends whenever you
            visit our app or when you access the app by or through a mobile
            device. This usage data may include information such as:
            <ul>
              <li>
                <Text>The pages of our app that you visit</Text>
              </li>
              <li>
                <Text>The time and date of your visit</Text>
              </li>
              <li>
                <Text>The time spent on those pages</Text>
              </li>
            </ul>
          </Text>
          {/* </Card> */}

          {/* <Card> */}
          <Heading paddingLeft={"1em"} level={6}>
            c. Location Data
          </Heading>
          <Text style={styles.tabContent}>
            We may use and store information about your location if you give us
            permission to do so ("Location Data"). We use this data to provide
            features of our service, to improve and customize our service.
          </Text>
          {/* </Card> */}

          {/* <Card> */}
          <Heading level={5}>3. How We Use Your Information</Heading>
          <Text style={styles.tabContent}>
            We use the collected data for various purposes:
            <ul>
              <li>
                <Text>To provide and maintain our service</Text>
              </li>
              <li>
                <Text>To notify you about changes to our service</Text>
              </li>
              <li>
                <Text>
                  To allow you to participate in interactive features of our
                  service
                </Text>
              </li>
              <li>
                <Text>To provide customer support</Text>
              </li>
            </ul>
          </Text>
          {/* </Card> */}
        </Card>
      </Card>
    </View>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    flexdirection: "column",
    alignitems: "center",
  },
  button: {
    cursor: "pointer",
    margin: 4,
    border: "1px solid #ddd",
    width: 225,
    ":hover": {
      backgroundcolor: "rgba(0, 0, 0, .1)",
    },
  },
  input: {
    padding: 8,
    height: 40,
    width: 225,
    border: "1px solid #ddd",
  },
  miniButton: {
    padding: 5,
    margin: 0,
  },
  submitButton: {
    padding: "10px 20px",
    margin: "10px 0px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "hsl(210, 50%, 10%)",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s ease 0s",
  },
  footer: {
    backgroundColor: "hsl(210, 50%, 10%)",
    color: "white",
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "flex",
    textAlign: "start",
  },
  container: {
    fontFamily: "'Roboto', sans-serif",
    margin: 0,
    padding: 0,
    backgroundColor: "#f4f4f4",
    color: "#333",
  },
  header: {
    alignItems: "center",
    backgroundColor: "#0d1a26",
    color: "white",
    // position: "sticky",
    width: "100%",
    top: 0,
    display: "flex",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
  headerImage: {
    height: "100px",
    verticalAlign: "middle",
  },
  headerTitle: {
    display: "inline",
    verticalAlign: "middle",
    fontSize: "30px",
    margin: 0,
    textAlign: "center",
    width: "100%",
  },
  navbar: {
    display: "flex",
    backgroundColor: "rgb(44, 62, 80)",
    padding: "10px 0",
    justifyContent: "center",
    width: "100%",
    top: "0",
    position: "sticky",
  },
  tabLink: {
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    color: "white",
    textAlign: "center",
    border: "none",
    backgroundColor: "inherit",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    ":hover": {
      backgroundColor: "#e67e22",
    },
  },
  tabContent: {
    display: "block",
    padding: "20px",
    background: "white",
    color: "#0d1a26",
    fontSize: "1em",
    //   boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s, box-shadow 0.3s",
    //   marginBottom: "10%",
    h3: {
      color: "#333",
      fontSize: "2em",
      // marginBottom: "10px",
      // marginTop: 0,
      textAlign: "center",
    },
    p: {
      textAlign: "justify",
      lineHeight: 1.6,
      marginTop: "1em",
    },
  },
  footerContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
};
export default PrivacyPolicy;
