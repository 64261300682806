import React, { useEffect, useMemo, useState } from "react";
import { listListings } from "./graphql/queries";
import { API, Amplify, Auth, Storage } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import config from "./aws-exports";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View,
  Heading,
  Card,
  Flex,
  Button,
  Loader,
  useTheme,
  Text,
  Alert,
} from "@aws-amplify/ui-react";
import "./App.css";
import EditCommission from "./EditCommission";
import { Listing } from "./models";
import { Link, Redirect } from "react-router-dom";
import { deleteS3Object } from "./services/s3utils";
import ListingUtils from "./services/listingsUtils";
import { Helmet } from "react-helmet";

Amplify.configure(config);

const MyListings: React.FC = () => {
  const [sortBy, setSortBy] = useState<string>("");
  const [listings, setListings] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [alertType, setAlertType] = useState<
    "error" | "success" | "warning" | "info" | undefined
  >(undefined);
  const [alertMessage, setAlertMessage] = useState<string>(
    "Choose a file you want to upload."
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedListing, setSelectedListing] = useState<Listing>(
    {} as Listing
  );
  const [lastFetched, setLastFetched] = useState<Date>(new Date());

  useEffect(() => {
    const fetchListings = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      setCurrentUser(currentUser);
      const listingsData = await API.graphql({ query: listListings });
      const listings = (listingsData as GraphQLResult<any>)?.data?.listListings
        ?.items;
      const filtered = listings.filter(
        (l: any) => l.owner === currentUser?.attributes?.sub
      );
      setIsLoading(false);
      setListings(filtered);
    };

    fetchListings();
  }, [listings.length, selectedListing.updatedAt, lastFetched]);

  const handleSort = (field: string) => {
    if (field === sortBy) {
      setSortBy("");
    } else {
      setSortBy(field);
    }
  };

  const handleSortDirection = () => {
    setSortBy((prevSortBy) => {
      if (prevSortBy.startsWith("-")) {
        return prevSortBy.substring(1);
      } else {
        return `-${prevSortBy}`;
      }
    });
  };

  const handleShowEdit = (listing: Listing) => {
    setSelectedListing(listing);
    setShowEditModal(true);
  };

  const { tokens } = useTheme();
  const sortedListings = useMemo(() => {
    let sorted = [...listings];
    if (sortBy === "Address") {
      sorted.sort((a: any, b: any) => {
        return a.address > b.address ? 1 : -1;
      });
    } else if (sortBy === "Rate") {
      sorted.sort((a: any, b: any) => {
        return a.commission - b.commission;
      });
    }

    const getSortSymbol = (field: string) => {
      if (field === sortBy) {
        return "▲";
      } else if (field === sortBy) {
        return "▼";
      } else {
        return "";
      }
    };
    return sorted;
  }, [listings, sortBy]);

  const handleConfirmDelete = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsLoading(true);
    const key = `${currentUser?.attributes.email}/${selectedListing.address}`;

    Storage.remove(key, { level: "public" }).then((response) => {
      console.log(response);
      setAlertMessage(`Contract deleted.`);
      setAlertType("success");
      setShowAlert(true);
    });

    const listingUtils = new ListingUtils();
    let deleteListingResponse = listingUtils
      .deleteListing({ address: selectedListing.address })
      .then((response) => {
        console.log(response);
        setShowAlert(true);
        setAlertType("success");
        setAlertMessage(`Listing deleted successfully!`);
        window.location.reload();
      })
      .catch((error) => {
        setShowAlert(false);
        setAlertType("error");
        setAlertMessage("Listing did not delete properly.");
        console.log(error);
      });
  };

  const helmet = (
    <Helmet>
      <title>My Listings</title>
      <meta
        name="google-adsense-account"
        content="ca-pub-9591636847579215"
      ></meta>
      <meta name="description" content="View your listings." />
      <meta name="author" content="Cooperating Broker" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="My Listings" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="View your listings." />
      <meta
        property="og:url"
        content="https://www.cooperating-broker.com/mylistings"
      />
      <link rel="canonical" href="https://www.cooperating-broker.com/mylistings" />
      <meta
        name="keywords"
        content="Cooperating Broker, real estate, commission, buyer agent, 
        listing agent, NAR, buyer broker, market transparency, competition,
        transactions, law, regulation, seller commission, buyer commission"
      />
    </Helmet>
  );

  function handleCancelDelete(
    event: React.MouseEvent<HTMLButtonElement>
  ): void {
    setShowAlert(false);
  }

  return isLoading ? (
    <>
      {helmet}
      <Loader
        variation="linear"
        emptyColor={"hsl(210deg 29.03% 24.31%)"}
        filledColor={tokens.colors.white}
      />
    </>
  ) : (
    <View>
      {helmet}
      {showEditModal ? (
        <View>
          <Card>
            <Flex justifyContent={"space-between"}>
              <Button
                style={{ marginBottom: "1em" }}
                onClick={() => {
                  setLastFetched(new Date());
                  setShowEditModal(false);
                }}
              >
                Back
              </Button>
              <Button
                variation="warning"
                style={{ marginBottom: "1em" }}
                onClick={() => {
                  setAlertType("warning");
                  setAlertMessage(
                    "Are you sure you want to delete this listing?"
                  );
                  setShowConfirmation(true);
                  setShowAlert(true);
                  // setShowEditModal(false);
                  // setIsLoading(true);
                }}
              >
                Delete
              </Button>
            </Flex>
            {showAlert && (
              // <Card textAlign={"center"}>
              <Alert
                marginBottom={"1em"}
                hidden={showAlert}
                variation={alertType}
              >
                <Flex>
                  {alertMessage}
                  <br />
                  {/* <div> */}
                  {showConfirmation && (
                    <>
                      <Button onClick={handleConfirmDelete}>Yes</Button>
                      <Button onClick={handleCancelDelete}>No</Button>
                    </>
                  )}
                  {/* </div> */}
                </Flex>
              </Alert>
              // </Card>
            )}
            <EditCommission
              listing={selectedListing}
              listingId={selectedListing.address}
              props={currentUser}
            />
          </Card>
        </View>
      ) : (
        <View>
          <Card>
            {/* <Card> */}
            <Heading level={4} textAlign={"center"}>
              My Listings
            </Heading>
            {/* </Card> */}
            {listings.length > 0 ? (
              <Grid marginBottom={"3em"}>
                <Table cellSpacing={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={() => handleSort("Address")}>
                        <Heading>Address</Heading>
                      </TableCell>
                      <TableCell onClick={() => handleSort("Rate")}>
                        <Heading>Rate</Heading>
                      </TableCell>
                      <TableCell>
                        <Heading>Active?</Heading>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedListings.map((l: any, index: number) => (
                      // <TableRow key={index} onClick={() => handleClick([l])}>
                      <TableRow key={index}>
                        <TableCell style={{ cursor: "pointer" }}>
                          <Flex
                            onClick={() => handleShowEdit(l)}
                            alignContent={"center"}
                            alignItems={"center"}
                            direction={"row"}
                          >
                            {/* <Menu padding={"small"} size="small" display={"flex"}>
                            <MenuItem
                              onClick={() => handleShowEdit(l)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem>Delete</MenuItem>
                          </Menu> */}
                            {l.address}
                          </Flex>
                        </TableCell>
                        <TableCell>
                          {l.commissionType === "Flat Fee" ? "$" : ""}
                          {l.commission}
                          {l.commissionType === "Percentage" ? "%" : ""}
                        </TableCell>
                        <TableCell>{l.isActive ? "Yes" : "No"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            ) : (
              <Card textAlign={"center"}>
                <Heading level={5}>
                  No listings yet? {/* <Link to={"/setcommission"}> */}
                  <Link to={"/setcommission"}>Add Listing</Link>
                  {/* <Button onClick={() => <Redirect to={"/setcommission"}/> }>Add Listing</Button> */}
                  {/* </Link> */}
                </Heading>
              </Card>
            )}
          </Card>
        </View>
      )}
    </View>
  );
};

export default MyListings;
