import React, { useEffect, useState } from "react";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import {
  Alert,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Text,
  View,
} from "@aws-amplify/ui-react";
import CameraComponent from "./components/CameraJS";
import AWS from "aws-sdk";
import { ConsoleLogger } from "@aws-amplify/core";

interface ManageAgreementProps {
  onFileUpload?: (file: File) => void;
  selectedAddress?: any;
  user: { props: any; sub: string; email: string; attributes: any };
  history: any;
  location: any;
  listing: any;
  setIsLoading: (isLoading: boolean) => void;
  //  setIsLoading = (isLoading) => setIsLoading(true), // Set setIsLoading to true by default
}

//logger
const logger = new ConsoleLogger("manage-agreements");

//add function to delete s3 object
const ManageAgreements: React.FC<ManageAgreementProps> = ({
  onFileUpload,
  selectedAddress,
  user,
  setIsLoading = (isLoading) => setIsLoading(isLoading),
}) => {
  // setIsLoading(true);
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string>("");
  const [agreement, setAgreement] = useState<string>("");
  const [uploadType, setUploadType] = useState<string>("file");
  const [contract, setContract] = useState<File | null>(null);
  const [alertType, setAlertType] = useState<
    "error" | "success" | "warning" | "info" | undefined
  >(undefined);
  const [alertMessage, setAlertMessage] = useState<string>(
    "Choose a file you want to upload."
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [picturePreview, setPicturePreview] = useState<string | null>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | File
  ) => {
    try {
      let selectedFile: File | null = null;
      if (event instanceof File) {
        setFilename(event.name);
        setFile(event);
        setContract(event);
        selectedFile = event;
        // return;
      } else {
        if (event.target.files?.length) {
          setFilename(event.target.files[0].name);
          selectedFile = event.target.files?.[0];
          setFile(selectedFile);
          setContract(selectedFile);
        }
      }
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result as string;
          setPicturePreview(dataUrl);
          setIsLoading(false);
        };
        reader.onerror = (error) => {
          console.error("Error...", error);
          return;
        };
        reader.readAsDataURL(selectedFile);
        onFileUpload?.(selectedFile);
        setFile(selectedFile);
      } else {
        setPicturePreview("");
        setFile(null);
      }
    } catch (error) {
      // console.error("Error uploading file:", error);
    }
  };

  // const deleteS3Object = (key: string) => {
  //   const params = {
  //     Bucket: "cbroker-contracts-bucket97c51-dev",
  //     Key: key,
  //   };

  //   const s3 = new AWS.S3();
  //   s3.deleteObject(params, (err, data) => {
  //     if (err) {
  //       console.error(`Error deleting S3 object with key ${key}:`, err);
  //     } else {
  //       console.log(`S3 object with key ${key} deleted successfully.`);
  //     }
  //   });
  // };

  // const deleteListing = async (input: { input: { id: string } }) => {
  //   const graphqlData = await API.graphql(
  //     graphqlOperation(deleteListing, input)
  //   );
  //   if (graphqlData) {
  //     // setAlertType("success");
  //     return graphqlData;
  //   } else {
  //     console.error("Error deleting listing:", graphqlData);
  //   }
  // };

  const fetchAgreements = async () => {
    try {
      if (!selectedAddress) {
        return;
      }
      const currentCredentials = await Auth.currentCredentials();
      AWS.config.update({
        region: "us-east-1", // Replace with your Cognito region
        credentials: Auth.essentialCredentials(currentCredentials),
      });

      let key = "";

      if (selectedAddress?.owner === user.sub) {
        key = `public/${user.email}/${selectedAddress?.address}`;
      }

      const params = {
        Bucket: "cbroker-contracts-bucket97c51-dev",
        Key: key,
      };

      const s3 = new AWS.S3();
      const signedUrl = await new Promise<string>((resolve, reject) => {
        s3.getSignedUrl("getObject", params, (err, url) => {
          if (err) {
            reject(err);
          } else {
            // console.log(url);
            resolve(url);
          }
        });
      });

      // console.log(signedUrl);
      let blob: Blob | undefined;
      try {
        const response = await fetch(signedUrl, {
          method: "GET",
          mode: "cors",
        });
        if (response.status === 404) {
          setPicturePreview(null);
          logger.info("No document for this listing.");
          return;
          // throw new Error("No document for this listing.");
        }
        blob = await response.blob();
      } catch (error) {
        setPicturePreview(null);
        logger.error("Error fetching S3 object:", error);
      }

      const dataUrl = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result as string;
          setPicturePreview(dataUrl);
          setIsLoading(false);
          // resolve(reader.result as string);
        };
        reader.onerror = reject;
        if (blob instanceof Blob && blob.size > 0) {
          reader.readAsDataURL(blob);
        } else {
          reject("The fetched data is not a Blob.");
        }
      });

      // Update agreements state
      setAgreement(dataUrl);
      setIsLoading(false);
    } catch (error) {
      // console.error("Whoops, the contracts did not load properly.", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchAgreements();
    };
    fetchData();
  }, []);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleDelete = () => {
    setShowConfirmation(true);
  };
  
  const handleConfirmDelete = async () => {
    // setIsLoading(true);
    
    // const key = `${user.email}/${selectedAddress.address}`;
    const key = `${user?.email}/${selectedAddress?.address}`;
    const removedFile = await Storage.remove(key, {
      level: "public",
      bucket: "cbroker-contracts-bucket97c51-dev",
    })
      .then((response) => {
        logger.info(response);
        setPicturePreview(null);
        setAgreement("");
        setFilename("");

        setShowConfirmation(false);
        setAlertMessage(`Commission Agreement deleted successfully!`);
        setAlertType("success");
        setShowAlert(true);
        
        // window.location.reload();
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlertMessage("Commission Agreement did not delete properly.");
        logger.info(error);
      });

    // const currentCredentials = await Auth.currentCredentials();
    // AWS.config.update({
    //   region: "us-east-1", // Replace with your Cognito region
    //   credentials: Auth.essentialCredentials(currentCredentials),
    // });
    // const signedUrl = await Storage.get(key, { level: "public" });
    // const valid = await fetch(signedUrl as string).then((response) => {
    //   return response.ok ? true : false;
    // });

    // if (valid) {
    //   await deleteS3Object(key, user).then((response) => {
    //     console.log(response);
    //     setShowAlert(true);
    //     setAlertType("success");
    //     setAlertMessage(`Listing deleted successfully!`);
    //     // window.location.reload();
    //   });
    // }

    //   console.log(response);
    //   setAlertType("success");
    //   setAlertMessage("Listing deleted successfully!");
    // }).catch((error) => {
    //   setAlertType("error");
    //   setAlertMessage("Listing did not delete properly.");
    //   console.log(error);
    // });
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  return (
    <Card>
      <Heading level={6} marginTop={"0em"}>
        Commission Agreement
      </Heading>
      <Grid>
        {picturePreview ? (
          <View>
            <Flex
              id="preview"
              direction={"column"}
              style={{
                verticalAlign: "middle",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {/* <Card style={{}}> */}
              <img
                id="contractPreview"
                style={{ width: "75%" }}
                src={picturePreview}
                alt="preview"
                />
              <Text>{contract?.name ?? ""}</Text>
              <Button
                size="small"
                variation="warning"
                height={"50px"}
                onClick={handleDelete}
              >
                Delete
              </Button>
              {/* </Card> */}
            </Flex>
          </View>
        ) : (
          <View style={{ alignContent: "center" }}>
            <Text>{contract?.name ?? ""}</Text>
            <CameraComponent
              handleFileChange={handleFileChange}
              picturePreview={picturePreview}
            />
          </View>
        )}
      </Grid>
      {showAlert && (
        <Alert hidden={showAlert} variation={alertType}>
          {alertMessage}
        </Alert>
      )}
      {showConfirmation && (
        <Alert variation="error" style={{ marginTop: "1em" }}>
          <Flex style={{ alignItems: "center" }}>
            <Text>Are you sure you want to delete this agreement?</Text>
            {/* <div> */}
            <Button onClick={handleConfirmDelete}>Yes</Button>
            <Button onClick={handleCancelDelete}>No</Button>
            {/* </div> */}
          </Flex>
        </Alert>
      )}
    </Card>
  );
};

export default ManageAgreements;
